import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Navbar from '../Homepage/Navbar';
import './Register.css'

const Register = () => {
  return (
    <div>
    <Navbar/>
    <div className='register_wrapper d-flex flex-row justify-content-center mx-auto'>
        <Container className='container_register_form d-flex justify-content-center px-5'>
            <div className="register__form d-flex flex-column">
                <Row>
                    <Col className='px-5 pb-5'>
                        <h2 className=' mt-5' style={{fontSize:'32px',fontFamily:'inter', fontWeight:'500'}}>Register</h2>
                        
                        <h2 className=' ' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'300'}}>Already have an account?&nbsp;<a className='a_signin' href='signin_grahakpg'><span style={{fontWeight:'700', color:'#34495E'}}>Sign in</span></a></h2>
                        
                        <h2 className=' mt-4' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Email</h2>

                        <input className=' w-100 ps-3 input_register' style={{height:'62px'}} type="text" placeholder='Your Email' />

                        <h2 className=' mt-4' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Full Name</h2>

                        <input className=' w-100 ps-3 input_register' style={{height:'62px'}} type="text" placeholder='Full name' />
                        <h2 className=' mt-4' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Username</h2>

                        <input className=' w-100 ps-3 input_register' style={{height:'62px'}} type="text" placeholder='Username' />

                        <h2 className=' mt-4' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Phone</h2>

                        <input className=' w-100 ps-3 input_register' style={{height:'62px'}} type="text" placeholder='Phone' />
                        <h2 className=' mt-4' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Password</h2>

                        <input className=' w-100 ps-3 input_register' style={{height:'62px'}} type="text" placeholder='Password' />

                        <h2 className=' mt-4' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Confirm Password</h2>

                        <input className=' w-100 ps-3 input_register' style={{height:'62px'}} type="text" placeholder='Confirm Password' />

                        <Form className='mt-4 d-flex flex-row'>
                            {['checkbox'].map((type) => (
                                <div key={`default-${type}`} className="d-flex flex-row align-items-center">
                                <Form.Check 
                                    type={type}
                                    id={`default-${type}`}
                                />
                                 <h2 className='px-3 my-auto' style={{fontSize:'16px', fontWeight:'300', fontFamily:'inter'}}>I have read and agree to the <a href="/term_condition_grahakpg" className='term_services_register'><span className='term_services'>Term of Service</span></a></h2>
                                </div>

                            ))}
                            </Form>

                        <button className='w-100 mt-4 button_signup' >Sign Up</button>

                    </Col>
                </Row>

            </div>
        </Container>
        
    </div>
    </div>
  )
}

export default Register