import React, { useEffect, useState, useCallback } from 'react';
import './InputPublicAddress.css'
import alertLogo from '../../assets/alertlogo.png'
import warningLogo from '../../assets/warninglogo.png'
import NavbarSell from '../Navbar/NavSell';
import Footer from '../Footer/Footer';
import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import NumberFormat from "react-number-format";
import { checkByPublicAddressApi, checkRatesApi, registerPublicAddress } from '../../../services/Api';
import VerifyLogo from '../../assets/verify.png'
import { ToastContainer, toast } from 'react-toastify';




function InputPublicAddress() {
    const [public_address, setpublic_address] = useState('')
    const [show, setShow] = useState(false);
    const [notHaveWallet, setNotHaveWallet] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [warning, setWarning] = useState(false);
    const [rateKpgSell, setRateKpgSell] = useState()
    const [openVerify, setOpenVerify] = useState(false)
    const [paymentMethodUserInfo, setPaymentMethodUserInfo] = useState(false)
    const [fullname, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setphone] = useState('')
    const [noAccountBetaMask, setNoAccountBetaMask] = useState(false)
    const [limitAccountFull, setLimitAccountFull] = useState(false)
    const [emptyInput, setEmptyInput] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [showWarningAlert, setWarningAlert] = useState(false);
    const [onProgressKycAlert, setOnProgressKycAlert] = useState(false);


    const moveToInputBankAccount = useNavigate()

    const [dataKepengQuantity, setDataKepengQuantity] = useState({
        nominalKpg: 0,
        convertKpgToIDR: 0
    })
    function handleClick() {
        moveToInputBankAccount('/seller-bank-info')
    }

    const getKpgRatesSell = useCallback(async () => {
        const rateData = await checkRatesApi();
        setRateKpgSell(rateData.sell)
        console.log(rateData)

    }, [checkRatesApi])

    useEffect(() => {
        getKpgRatesSell()
        // const orderKepengDataFromLocal = localStorage.getItem('sell-transaction')
        const orderKepengDataFromLocal = localStorage.getItem('kepeng-transaction')

        const dataKepengQuantity = JSON.parse(orderKepengDataFromLocal)
        console.log("type", dataKepengQuantity.transaction_type)

        setDataKepengQuantity(dataKepengQuantity)
    }, [])

    const onSubmit = async () => {
        const data = {
            transaction_type: dataKepengQuantity?.transaction_type,
            public_address,
            amount_kpg: dataKepengQuantity?.quantity,
            convertKpgToIDR: dataKepengQuantity?.convertKpgToIDR,
            rate: rateKpgSell,
            adminFee: dataKepengQuantity?.adminFee,
            totalRecieve: dataKepengQuantity?.totalRecieve


        }
        if (public_address === '') {
            setShow(true)
            // setTimeout(()=>{
            //     setShow(false)
            // },3000)
            console.log('please enter your public address')
        }


        else {
            // const data = new FormData();
            // data.append('quantity',form.nominalKpg)
            // data.append('',form.convertKpgToIDR)
            // data.append('public_address',public_address)
            // const getLocalForm = await localStorage.getItem('sell-transaction');
            // const form = JSON.parse(getLocalForm);
            //--------------------------------- 

            console.log('data public address', data.public_address)

            const response = await checkByPublicAddressApi(data)
            console.log('----------')
            console.log('response', response)
            console.log('---- error', response.error)
            console.log('---- status', response.status)


            if (response?.status === false) {
                console.log('status false')
                if (response?.message === "Oops.. Looks like you haven't GrahaKPG account yet. Create now") {
                    console.log('doesnt have account')
                    // setOpenVerify(true)
                    // console.log('responses error:', response.message)
                    setWarningAlert(true)
                    setOpenVerify(true)
                    setNoAccountBetaMask(false)
                    const dataPublicAdrressKYC = {
                        transaction_type: dataKepengQuantity?.transaction_type,
                        public_address,
                        amount_kpg: dataKepengQuantity?.quantity,
                    }

                    localStorage.setItem('kepeng-transaction', JSON.stringify(dataPublicAdrressKYC))
                    setAlertMessage(response.message)
                    setShowAlert(true)
                    // setNoAccountBetaMask(true)
                    // setLimitAccountFull(false)
                } else if (response?.message === "Oops.. It seems like you have reach the transaction limit. Verify to continue") {
                    console.log('limit was full')
                    // setOpenVerify(true)
                    setWarningAlert(true)
                    setNoAccountBetaMask(false)
                    const dataPublicAdrressKYC = {
                        transaction_type: dataKepengQuantity?.transaction_type,
                        public_address,
                        amount_kpg: dataKepengQuantity?.quantity,
                    }

                    localStorage.setItem('kepeng-transaction', JSON.stringify(dataPublicAdrressKYC))
                    // console.log('responses error:', response.message)
                    setAlertMessage(response.message)
                    setShowAlert(true)
                    setLimitAccountFull(true)
                    // setTimeout(()=>{
                    //     setShowAlert(false)
                    // }, 3000)

                }

                else if (response.message === "You have to activate your account to purchase Kepeng. Activated now") {
                    setOpenVerify(true)
                    setWarningAlert(true)
                    setNoAccountBetaMask(false)
                    const dataPublicAdrressKYC = {
                        transaction_type: dataKepengQuantity?.transaction_type,
                        public_address,
                        amount_kpg: dataKepengQuantity?.quantity,
                    }

                    localStorage.setItem('kepeng-transaction', JSON.stringify(dataPublicAdrressKYC))
                    setWarning(true)


                }
                else {
                    console.log('unregister account')
                    toast.error('Please enter valid public address')
                    // setPaymentMethodUserInfo(true)
                    if (fullname === "" && phone === "" && email === "") {
                        console.log("Please fullfill all the form")
                    } else {
                        // const onSubmitUnRegisteredData = () =>{

                        const dataUnRegistered = {
                            name: fullname,
                            email: email,
                            phone: phone,
                            public_address,
                            transaction_type: dataKepengQuantity?.transaction_type,
                            amount_kpg: dataKepengQuantity?.quantity,
                            convertKpgToIDR: dataKepengQuantity?.convertKpgToIDR,
                            rate: dataKepengQuantity?.rate,
                            adminFee: dataKepengQuantity?.adminFee,
                            totalRecieve: dataKepengQuantity?.totalRecieve
                        }
                        const response = await registerPublicAddress(dataUnRegistered)
                        if (response.success === false) {
                            console.log('----', response?.success)
                            console.log('please fullfill all the form')

                        } else {
                            localStorage.setItem('kepeng-transaction', JSON.stringify(dataUnRegistered))
                            console.log('phone number ----------', phone)
                            console.log(dataUnRegistered)
                            // handleClick()
                        }
                    }
                }
            }


            else {
                console.log('account status', response?.account_status)

                if (response?.account_status === 'verification on progress') {
                    setAlertMessage(response?.account_status)
                    setOnProgressKycAlert(true)
                    setTimeout(() => {
                        setOnProgressKycAlert(false)
                    }, 3000)



                } else {
                    console.log('response dataa -------', response?.user)

                    console.log('good public address')
                    console.log('-----warning-------')
                    console.log('-----regis-------')

                    const dataRegistered = {
                        name: response?.name,
                        email: response?.email,
                        phone: response?.phone,
                        transaction_type: dataKepengQuantity?.transaction_type,
                        public_address,
                        amount_kpg: dataKepengQuantity?.quantity,
                        convertKpgToIDR: dataKepengQuantity?.convertKpgToIDR,
                        // rate:rateKpgBuy,
                        rate: dataKepengQuantity?.rate,
                        adminFee: dataKepengQuantity?.adminFee,
                        totalRecieve: dataKepengQuantity?.totalRecieve
                    }
                    localStorage.setItem('kepeng-transaction', JSON.stringify(dataRegistered))
                    console.log(dataRegistered)
                    handleClick()
                }

                // }



            }
            //    if(response.message === "Oops.. Looks like you haven't created Betamax wallet yet. Create now"){
            //        console.log('responses error:', response.message)
            //     //    setOpenVerify(true)

            //        setNotHaveWallet(true)
            //             setTimeout(()=>{
            //         setNotHaveWallet(false)
            //     },3000)
            //    }else if(response.message === "You have to activate your account to purchase Kepeng. Activated now"){
            //     setOpenVerify(true)

            //     setWarning(true)
            //     setTimeout(()=>{
            //         setWarning(false)
            //     },3000)

            //    }else{
            //     console.log('respose success:', response.message)
            //     // localStorage.setItem('sell-transaction',JSON.stringify(data))
            //     localStorage.setItem('kepeng-transaction',JSON.stringify(data))

            //     console.log("SELLER PUBLIC ADDRESS :",data) 

            //     handledClick()

            //    }



            // localStorage.setItem('public-address-seller',JSON.stringify(data))
            // console.log("SELLER PUBLIC ADDRESS :",data) 

            // const response = await checkByPublicAddressApi(data)
            // if(response.error){
            //     console.log(response.message)
            // }else{
            //     console.log("success")
            // }
            // handledClick()
        }
    }


    return (
        <div>
            <NavbarSell />

            <Container className="publicaddresscontainer">
                <Form>
                    <Row className="mainRowPublicAddress">
                        <Col className="row-input-seller-public-address" xs={8}>
                            <Form>
                                <Form.Label className="publicaddresslabel">Your Public Address (2/3)</Form.Label>
                                <Form.Text className="fillpublicaddress">
                                    Enter your wallet public address to sell Kepeng (KPG)
                                </Form.Text>
                                <Form.Group>
                                    <Form.Label className="publicaddress">Seller Public Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Public Address"
                                        value={public_address}
                                        onChange={(event) => setpublic_address(event.target.value)}
                                    />
                                    {/* <Form.Text className="totalidr">
                        Total (Rupiah - IDR)
                        </Form.Text>
                        <Form.Label className="kepengtoidr">0,00 IDR</Form.Label> */}
                                </Form.Group>
                            </Form>
                            <Row>
                                {
                                    show ? <Alert className="nothavewallet" variant="danger">
                                        <img className="alertlogo" src={alertLogo} />
                                        <p className="nothavewallettext">
                                            Please enter your public address
                                            <span className='mx-6 my-auto' onClick={() => setShow(false)}
                                                style={{ cursor: 'pointer' }}>(x)close</span>
                                        </p>

                                    </Alert> : null
                                }
                            </Row>
                            <Row>
                                {
                                    noAccountBetaMask ? <Alert className="nothavewallet" variant="danger">
                                        <img className="alertlogo" src={alertLogo} />
                                        <p className="nothavewallettext">
                                            Oops.. Looks like you haven't created GrahaKPG account yet.<a className='create-now-public-address-buy-alert'
                                            //   onClick={()=>{
                                            //             setPaymentMethodUserInfo(true)
                                            //         }}
                                            ><strong>Create now</strong></a> <span className='mx-6 my-auto' onClick={() => setNoAccountBetaMask(false)}
                                                style={{ cursor: 'pointer' }}>(x)close</span>
                                        </p>

                                    </Alert> : null
                                }
                            </Row>
                            <Row>
                                {

                                    warning ? <Alert className="notactiveaccount" variant="warning">
                                        <img className="cautionlogo" src={warningLogo} />
                                        <p className="notactiveaccounttext">
                                            You have to activate your account to purchase Kepeng. <a onClick={() => setOpenVerify(true)}><u><strong>Activated now</strong></u></a>
                                            <span className='mx-6 my-auto' onClick={() => setWarningAlert(false)}
                                                style={{ cursor: 'pointer' }}>(x)close</span> </p>

                                    </Alert> : null
                                }
                            </Row>
                            <Row>
                                {

                                    onProgressKycAlert ? <Alert className="notactiveaccount" variant="warning">
                                        <img className="cautionlogo" src={warningLogo} />
                                        <p className="notactiveaccounttext">
                                            {alertMessage} <span className='mx-6 my-auto' onClick={() => setOnProgressKycAlert(false)}
                                                style={{ cursor: 'pointer' }}>(x)close</span>
                                        </p>

                                    </Alert> : null
                                }
                            </Row>

                        </Col>
                        <Col className="public-address-col" xs={5}>
                            {/* <Form> */}
                            <Form.Label className="ordersummarypublicaddress">Order Summary</Form.Label>
                            <Form.Text className="conversionidr">
                                Sell - {dataKepengQuantity.nominalKpg} KPG
                                <span>
                                    <NumberFormat
                                        value={dataKepengQuantity.convertKpgToIDR}
                                        displayType="text"
                                        thousandSeparator=","
                                        decimalSeparator="."
                                    />
                                    IDR </span>
                            </Form.Text>
                            <Form.Text className="fee">
                                Admin fee <span><NumberFormat
                                    value={dataKepengQuantity?.adminFee}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeperator='.' />
                                    &nbsp;IDR </span>
                            </Form.Text>
                            <Form.Text className="totalrecieve">
                                Total Recieve
                                <span>
                                    <NumberFormat
                                        value={dataKepengQuantity?.totalRecieve}
                                        displayType="text"
                                        thousandSeparator=","
                                        decimalSeparator="."
                                    />IDR </span>
                            </Form.Text>
                            <Row className="allbuttonrow">
                                <Row className="eachbtn">
                                    <Button
                                        className="buttonnext"
                                        variant="primary"
                                        type="button"
                                        onClick={onSubmit}
                                    >
                                        Next
                                    </Button>

                                </Row>
                                <Row className="eachbtnrow">
                                    <Link to="/kpg-sell " className="buttonback">
                                        {/* <Button 
                            className="buttonback" variant="light" type="submit"> */}
                                        Back
                                        {/* </Button> */}
                                    </Link>
                                </Row>
                            </Row>


                        </Col>


                    </Row>
                </Form>
                {openVerify && <div id='id01' className='modalVerify'>
                    <div className='verifyBackground'>
                        <div className="verifyContainer">
                            <div className="body">
                                <Row className='d-flex flex-column'>
                                    <p className='d-flex justify-content-end'
                                        onClick={() => setOpenVerify(false)}
                                        style={{ cursor: 'pointer' }} >X</p>
                                    <Col className='pt-5 px-4 '>

                                        <img src={VerifyLogo} alt="/" style={{ width: '135px', height: '135px' }} />

                                        <h2 className='verify_title d-flex w-auto pt-5' style={{ fontSize: '32px', fontWeight: '500', color: '#34495E', fontFamily: 'inter' }}>
                                            Secure transactions with Verified Accounts
                                        </h2>
                                        <h2 className='verify_info d-flex w-auto px-3 pt-3' style={{ fontSize: '16px', fontWeight: '300', color: '#A9A9A9' }}>
                                            For the purposes of convenience and security of transactions,you have to verify your account berfore make a transaction in GrahaKPG
                                        </h2>

                                        <a href="/choose_country" className='a_button_verify d-flex flex-row w-100 pt-3'>
                                            <button className='button_verify w-100' style={{ height: '64px', color: 'white', backgroundColor: '#34495E', borderRadius: '8px', fontSize: '20px', fontFamily: 'inter', fontWeight: '500', textDecoration: 'none !important' }}>
                                                Send Instruction
                                            </button>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>}
                {paymentMethodUserInfo && <div id='id01' className='modalVerify'>
                    <div className='verifyBackground'>
                        <div className="verifyContainer">
                            <div className="body">
                                <Row className='"register__form  d-flex flex-column text-start'>
                                    <Col className='px-4 py-auto pb-4 text-left'>
                                        <h2 className=' mt-5 text-left'
                                            style={{ fontSize: '32px', fontFamily: 'inter', fontWeight: '500' }}>Personal Information</h2>

                                        <h2 className='w-75' style={{ fontSize: '16px', fontFamily: 'inter', fontWeight: '300', color: "#A9A9A9" }}>You’ve input your name and email to continue the transaction</h2>

                                        <h2 className=' mt-4' style={{ fontSize: '16px', fontFamily: 'inter', fontWeight: '500', color: '#34495E' }}>Email</h2>

                                        <input
                                            className=' w-100 ps-3 input_register'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            style={{ height: '62px' }} type="text" placeholder='Your Email' />

                                        <h2 className=' mt-4' style={{ fontSize: '16px', fontFamily: 'inter', fontWeight: '500', color: '#34495E' }}>Full Name</h2>

                                        <input
                                            className=' w-100 ps-3 input_register'
                                            value={fullname}
                                            onChange={(e) => setFullName(e.target.value)}
                                            style={{ height: '62px' }} type="text" placeholder='Full name' />


                                        <h2 className=' mt-4' style={{ fontSize: '16px', fontFamily: 'inter', fontWeight: '500', color: '#34495E' }}>Phone Number</h2>

                                        <input
                                            className=' w-100 ps-3 input_register'
                                            value={phone}
                                            onChange={(e) => setphone(e.target.value)}
                                            style={{ height: '62px' }} type="text" placeholder='phone' />
                                        <a className='a_button_verify d-flex flex-row w-100 pt-5'>
                                            <button className='button_verify w-100'
                                                onClick={onSubmit}
                                                style={{ height: '64px', color: 'white', backgroundColor: '#34495E', borderRadius: '8px', fontSize: '20px', fontFamily: 'inter', fontWeight: '500', textDecoration: 'none !important' }}>
                                                Continue
                                            </button>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>}
                <ToastContainer />
            </Container>
            <Footer />
        </div>
    );
}

export default InputPublicAddress;