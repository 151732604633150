
import './Homepage.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from 'react-bootstrap';
import Navbar from './Navbar';
import Header from './Header';
import BuyAndSellCard from './BuyAndSellCard';
import CompaniesLogo from './CompaniesLogo';
import SmartestChoiceTitle from './SmartestChoiceTitle';
import SmartChoiceInfo from './SmartChoiceInfo';
import Card from './Card';
import GetInTouchText from './GetInTouchText';
import GetInTouchForm from './GetInTouchForm';
import Footer from './Footer';
import{useEffect} from 'react'

// import Particles from 'react-tsparticles'
// import { loadFull } from "tsparticles";

function Homepage() {
  useEffect(()=>{
      window.scrollTo(0,0)
  })
  return (

    <div className="App">
      <Navbar/>
      <Header/>
      <BuyAndSellCard/>
        <CompaniesLogo/>
        <SmartestChoiceTitle/>
        <SmartChoiceInfo/>
        <Card/>
        {/* <GetInTouchText/>
        <GetInTouchForm/> */}
      <Footer/>
    </div>
  );
}

export default Homepage;

