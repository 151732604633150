import React, { useEffect, useState,useRef } from 'react'
import { ButtonGroup, Col, Container, Dropdown, DropdownButton, Row,Alert, SplitButton, Form } from 'react-bootstrap'
import { checkHistoryAPI } from '../../../services/Api'
import './HeroTransactionHistory.css'
import ArrowRight from '../../assets/arrow-right.png'
import NotSearchYetImg from '../../assets/NotSearchYet.png'
import questionLogo from '../../assets/questionLogo.png'
import Popup from 'reactjs-popup'

import { Link } from 'react-router-dom'


const HeroTransactionHistory = () => {

  const[dropDownHistoryTypeTransaction, setDropDownHistoryTypeTransaction] = useState(true)
  const[addressOrCode,setAddressOrCode] = useState('')
  const [transactionType,setTransactionType] = useState('all')
  const [totalTransaction,setTotalTransaction] =useState('')
  
  const [showSortMenu,setShowSortMenu] = useState(false)
  const [notSearchYet,setNotSearchYet] = useState(true)
  const [notFound,setNotFound] = useState(false)
  const [containerNoScrollBar,setContainerNoScrollBar]=useState(true)
  const [containerScrollBar,setContainerScrollBar]=useState(false)
  const [statusbgClass,setstatusbgClass] = useState('')





  let [transactions,setTransaction] =useState ([])
  let [transactionsFiltered,setTransactionFiltered] =useState ([])

  const btnRef = useRef();

  const selectedTypeTransaction =(e)=>{
    setTransactionType(e)
  }

  useEffect(()=>{

  const closeDropDownType = e => {
    if(e.path[0] !== btnRef.current){
      setDropDownHistoryTypeTransaction(false);
    }
  };


    document.body.addEventListener('click', closeDropDownType);
    return() => document.body.removeEventListener('click', closeDropDownType);

  }, []);



  const onSubmit = async () => {
    console.log('---- data subtring' , addressOrCode?.substring(0,2))
    let data = ''
    if(addressOrCode?.substring(0,2) === "0x"){
      data ={
          public_address:addressOrCode,
          code:''
        }
      }
        else{
          data= {
            code:addressOrCode,
            public_address:''
          }
      };
    console.log("data after subtring ---",data)

    transactions = await checkHistoryAPI(data)

    console.log('---data response buy',transactions?.dataHistory?.data?.buy_transaction)
  
    // handledTransactionTypeChange("all")

    setTransaction(transactions)
    handledTransactionTypeChange("all")


    if(transactions.error){
      console.log('trasaction error', transactions.message)
      setNotFound(true)
      setShowSortMenu(true)
      setNotSearchYet(false)
      setTotalTransaction(0)
      setContainerScrollBar(false)

    }
    else{
      setShowSortMenu(true)
      setNotSearchYet(false)
      setContainerScrollBar(true)
      setContainerNoScrollBar(false)

      console.log('history transaction',transactions)



      localStorage.setItem('check-history-transaction', JSON.stringify(data))



    }
  }
 function handledTransactionTypeChange(value){
   
  let transactionHistory = [];
   if(value === "buy"){
    if(transactions?.dataHistory?.data?.buy_transaction?.length > 0  ){
      
      transactions?.dataHistory?.data?.buy_transaction?.map((history)=>{
        transactionHistory.push(history)
      })
   
    }else{
      transactionHistory.push(transactions?.dataHistory?.data?.buy_transaction)
    }
   }else if( value === "sell"){
    if(transactions?.dataHistory?.data?.sell_transaction?.length > 0  ){
      
      transactions?.dataHistory?.data?.sell_transaction?.map((history)=>{
        transactionHistory.push(history)
      })
   
    }else{
      transactionHistory.push(transactions?.dataHistory?.data?.sell_transaction)
    }
   }else{
    if(transactions?.dataHistory?.data?.buy_transaction?.length > 0 || transactions?.dataHistory?.data?.buy_transaction?.length ){
      
      transactions?.dataHistory?.data?.buy_transaction?.map((history)=>{
        transactionHistory.push(history)
      })
      transactions?.dataHistory?.data?.sell_transaction?.map((history)=>{
        transactionHistory.push(history)
      })

    }else if (transactions?.dataHistory?.data?.buy_transaction?.id){
      transactionHistory.push(transactions?.dataHistory?.data?.buy_transaction)
    }else if(transactions?.dataHistory?.data?.sell_transaction?.id){
      transactionHistory.push(transactions?.dataHistory?.data?.sell_transaction)

    }


   }
   
   setTransactionFiltered(transactionHistory)
   
   setTotalTransaction(transactionHistory?.length)

 }
 



  return (
    <div className="hero-transaction-history-wrapper">
      <Col className='w-100'>
          <Row className='justify-content-center align-items-center wrapper-hero'>
          <Container>
            <div className="main-info-transaction-history">
                <h2 className='info-1-transaction-history'>Find your Kepeng transaction here!</h2>
                <h2 className='info-2-transaction-history'>Enter your public address or ID Transaction<Popup className='popup_hero_transaction' trigger={<img className='ms-2' src={questionLogo} alt='/' style={{width:'20px' , height:'20px'}}/>}> <div>1. Both ID transaction and public address must be entered during buy process. <br />2. Only ID transaction needed to be entered during sell process </div>
                </Popup> </h2>
                <input type="text" className="input-address-publicID-transaction-history" 
                                placeholder="Public Address or ID Transaction" aria-label="Recipient's username" 
                                aria-describedby="basic-addon2" 
                                displayType="text"
                                value={addressOrCode}
                                onChange={(event)=>setAddressOrCode(event.target.value)}
                                
                                />
                <button className='btn-search-transaction-history'
                type="button"
                onClick={onSubmit}
                >Search</button>
            </div>
            </Container>
        </Row>
        <Container className='dropdown-type-sort-container'>
      
        <Row className='wrapper-transaction-history-info '>
          {
          showSortMenu?<div className="transaction-history-info d-flex row justify-content-between w-100 align-items-center">

            <h2 className='total-transaction-history'>We found <span className='bold-transaction-history'> {totalTransaction} transaction</span> </h2>

            
            <div className='row justify-content-end transaction_filter align-items-center'>

            <h2 className='transaction-history-type'>Transaction Type:</h2>
            <Form.Select className="dropdown_transaction_type "aria-label="Default select example" onChange={(e)=>{
                  setTransactionType(e.target.value);
                  handledTransactionTypeChange(e.target.value)
                  }
                }>
              <option>All</option>
              <option value="all">All</option>
              <option value="buy">Buy</option>
              <option value="sell">Sell</option>
            </Form.Select>
          </div>
          <hr className='divider-transaction-history-info'/>   
          </div>:null
          }
          {
          containerScrollBar?<Container className="container-history-transaction">
            {

              
        
              transactionsFiltered?.map((history)=>{
                console.log("data history ---",history)
                let statusBg = '';
                if(history?.status === "on progress" ){
                  statusBg ="status-bg-yellow"
                  console.log(statusBg)
                }else if (history?.status === "complete"){
                  statusBg ="status-bg-green"
                }else {
                  statusBg ="status-bg-red"
                }
                return <div key={history?.code}>
                <Row className="historylist" >
                      <div className="history-list-wrapper">
                      <Row className="history-list-row">
                      <Col className="history-list-col">
                      <Alert className={`status-alert ${statusBg}`} variant="warning">
                        {history?.status}
                      </Alert>
                      
                      
                      </Col>
  
                      <Col className="transaction-info" xs={6}>
                        <Form.Label className="public-address-history-lable">{history?.public_address}</Form.Label>
                        <Form.Label className={`status-order${history?.transaction_type==='sell'?'-sell':''}`}>{history?.transaction_type} <span className="span-order-created">• {history?.order_created}</span> </Form.Label>
                        <Form.Label className="public-address-history-lable-mobile">{history?.public_address?.length > 15 ?history?.public_address.substring(0,17)+"...":history?.public_address}</Form.Label>
                      </Col>
  
                      <Col className="see-detail-transaction">
                      <Link to={`/detail_transaction${history?.transaction_type==='sell'?'_sell':''}/${history?.code}`} className="order-detail">Detail <span className="arrow-detail"><img className="arrow-icon" src={ArrowRight} alt="" /></span> </Link>
                      </Col>
  
                      </Row>
                      </div>
                      
                    </Row>
                  
                    </div>
                
              })
            
            }
          
          

            
          </Container>:null
          }
          {
          containerNoScrollBar?<Container className ="no-scroll-bar">
          {
            notSearchYet?<Row className="historylist" >
                    <div className="history-list-wrapper-img">
                      <img className="img-not-search-yet" src={NotSearchYetImg} alt="" />
                   <Form.Text className="have-not-search">
                   You haven’t searched anything yet. When you do, it'll show up here.
                   </Form.Text>
                    </div>
                    
              </Row>:null
            }     
            {
            notFound?<Row className="historylist-not-found" >
                    <div className="history-list-wrapper-not-found">
                      
                   <Form.Text className="have-not-found">
                   We’re sorry, we couldn’t find results for your search:                    
                   </Form.Text>
                   <Form.Text className="have-not-found-data-public-address">
                   {addressOrCode}                   
                   </Form.Text>
                   <Form.Text className="have-not-found-message">
                   Check your public address or your transaction                   
                   </Form.Text>
                    </div>
                    
              </Row>:null
            }  
          </Container>:null
          }
        

        </Row>
        </Container>
        </Col>
      </div>
  )
}

export default HeroTransactionHistory