import React from 'react'
import NavbarHomepage from '../Homepage/Navbar'
import FooterTermCondition from './TermConditionFooter/TermConditionFooter'
import TermConditionInfo from './TermConditionInfo/TermConditionInfo';

const TermConditionPage = () => {
  return (
    <div className='term-condition-wrapper'>
        <NavbarHomepage/>
        <TermConditionInfo/>
        <FooterTermCondition/>
        {/* <Footer/> */}
    </div>
  )
}

export default TermConditionPage