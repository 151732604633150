import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './PaymentMethodBuy.css'
import AlertLogo from '../../assets/alertlogo.png'
import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap';
import QrisLogo from '../../assets/qrisLogo.png'
import BRILogo from '../../assets/bri.svg'
import BCALogo from '../../assets/bca.svg'
import BNILogo from '../../assets/bni.svg'
import MandiriLogo from '../../assets/mandiri.svg'
import NumberFormat from 'react-number-format';
import { useNavigate, Link, Router } from 'react-router-dom';
import NavbarSell from '../../Sell/Navbar/NavSell';
import Footer from '../../Sell/Footer/Footer'
import { buyKepeng, checkByPublicAddressApi, checkPaymentMethod,checkRatesApi } from '../../../services/Api';
import axios from 'axios';

function PaymentMethodBuy() {

    const listPayement = {
    "modern store":[
        {
            "payment_code": "ALFAMART",
            "payment_name": "Alfamart",
            "payment_description": "Bayar di gerai Alfamart",
            "payment_logo": "https://secure-payment.winpay.id/img/spi-alfamart.png",
            "payment_url": "https://secure-payment.winpay.id/api/ALFAMART",
            "payment_url_v2": "https://secure-payment.winpay.id/apiv2/ALFAMART",
            "is_direct": true
        },
        {
            "payment_code": "INDOMARET",
            "payment_name": "Indomaret",
            "payment_description": "Bayar di gerai Indomaret",
            "payment_logo": "https://secure-payment.winpay.id/img/spi-indomaret.png",
            "payment_url": "https://secure-payment.winpay.id/api/INDOMARET",
            "payment_url_v2": "https://secure-payment.winpay.id/apiv2/INDOMARET",
            "is_direct": true
        }
    ],
    "virtual account": [
        {
            "payment_code": "BNIVA",
            "payment_name": "BNI VIRTUAL ACCOUNT",
            "payment_description": "Bayar dengan BNI VIRTUAL ACCOUNT",
            "payment_logo": "https://secure-payment.winpay.id/img/spi-bni-va.png",
            "payment_url": "https://secure-payment.winpay.id/api/BNIVA",
            "payment_url_v2": "https://secure-payment.winpay.id/apiv2/BNIVA",
            "is_direct": true
        },
        {
            "payment_code": "BRIVA",
            "payment_name": "BRI VIRTUAL ACCOUNT",
            "payment_description": "Bayar dengan BRI VIRTUAL ACCOUNT",
            "payment_logo": "https://secure-payment.winpay.id/img/spi-bri-va.png",
            "payment_url": "https://secure-payment.winpay.id/api/BRIVA",
            "payment_url_v2": "https://secure-payment.winpay.id/apiv2/BRIVA",
            "is_direct": true
        },
        {
            "payment_code": "PERMATAVAC",
            "payment_name": "PERMATA VIRTUAL ACCOUNT",
            "payment_description": "Bayar dengan PERMATA VIRTUAL ACCOUNT",
            "payment_logo": "https://secure-payment.winpay.id/img/spi-permata-va.png",
            "payment_url": "https://secure-payment.winpay.id/api/PERMATAVAC",
            "payment_url_v2": "https://secure-payment.winpay.id/apiv2/PERMATAVAC",
            "is_direct": true
        },
        {
            "payment_code": "MANDIRIVA",
            "payment_name": "MANDIRI VIRTUAL ACCOUNT",
            "payment_description": "Bayar dengan MANDIRI VIRTUAL ACCOUNT",
            "payment_logo": "https://secure-payment.winpay.id/img/spi-mandiriva.png",
            "payment_url": "https://secure-payment.winpay.id/api/MANDIRIVA",
            "payment_url_v2": "https://secure-payment.winpay.id/apiv2/MANDIRIVA",
            "is_direct": true
        }
    ]}


    const [paymentMethod, setPaymentMethod] = useState('')
    const [payment_Method, setPayment_Method] = useState('')
    const [rateKpgBuy,setRateKpgBuy]= useState()
    const [status,setStatus]=useState('')
    const [VANumber,setVANumber]=useState('')
    const [checked, setChecked]= useState(false)


    const btnRef = useRef();
    const moveToBuyTransactionDetail = useNavigate();

    const [showAlert, setShowAlert] = useState(false);

    const [dataKepengQuantity, setDataKepengQuantity] = useState({
        quantity :0,
        convertKpgToIDR:0,
    })

    const [buyPublicAddress,setBuyPublicAddress] = useState({
        receiverPublicAddress:''
    })

    const getPaymentMethod = useCallback(async()=>{
        const payment_Method = await checkPaymentMethod()
        setPayment_Method(payment_Method)
        // console.log(paymentMethod)
    },[checkPaymentMethod])
    const getKpgRatesBuy = useCallback(async() => {
        const rateData = await checkRatesApi();
        setRateKpgBuy(rateData.buy)
        // console.log(rateData)

    },[checkRatesApi])


    useEffect(() =>{
        getPaymentMethod() 
        getKpgRatesBuy()        
        const selectUnlock = e => {
            if(btnRef && !btnRef.current.contains(e.target) ){
                setPaymentMethod('')
            }
            console.log(selectUnlock)
        };
       
        document.body.addEventListener('mouseDown', selectUnlock);
        return() => document.body.removeEventListener('mouseDown', selectUnlock);
    },[]);

    const handleSelectPayment = (selected) => {
        setPaymentMethod(selected);
        console.log(selected);
    }

    useEffect(()=> {
        // const orderKepengDataFromLocal = localStorage.getItem('buy-kepeng')
        const orderKepengDataFromLocal = localStorage.getItem('kepeng-transaction')
        const dataKepengOrderBuy = JSON.parse(orderKepengDataFromLocal)
        // const publicAddressFromLocal = localStorage.getItem('Public-Address-Receiver')
        // const dataReceiverPublicAddress =JSON.parse(publicAddressFromLocal)

        // console.log(dataKepengOrderBuy.quantity)
        // console.log(dataKepengOrderBuy.convertKpgToIDR)

        // console.log(dataKepengOrderBuy.public_address)
        console.log(dataKepengOrderBuy.rate)

        
        setDataKepengQuantity(dataKepengOrderBuy)
        // setBuyPublicAddress(dataKepengOrderBuy.public_address)
        // setRateKpgBuy(dataKepengOrderBuy.dataKepengQuantity.rateKpgBuy)
    },[])

    function handleClick(txId){
        moveToBuyTransactionDetail(`/detail_transaction/${txId}`)
   }


   const onSubmit = async () => {
    // const orderKepengDataFromLocal = localStorage.getItem('buy-kepeng')
    const orderKepengDataFromLocal = localStorage.getItem('kepeng-transaction')

    const dataKepengOrderBuy = JSON.parse(orderKepengDataFromLocal)
    const data = {
        // transaction_type:dataKepengOrderBuy?.transaction_type,
        public_address:dataKepengOrderBuy?.public_address,
        quantity: dataKepengOrderBuy?.amount_kpg,
        rate:dataKepengOrderBuy?.rate,
        payment_method:paymentMethod,
        // email:dataKepengQuantity?.email,
        // name:dataKepengQuantity?.name
    }
    if(!paymentMethod ){
        setShowAlert(true)
        setTimeout(()=>{
            setShowAlert(false)
        }, 3000)
        console.log('Please choose your payment method')
    }if (checked === false){
        console.log('check',false)
        toast.error('Please check to confirm the transaction')
    }
    
    else{
        console.log('pay request ----',data)
        console.log('checked ----',checked)

        const response = await buyKepeng(data)
        const timeDueMandiriva=response?.message?.split("sebelum jam ")[1]?.split(", Order ID Anda adalah")[0]
        const timeDueVA = response?.message?.split("(Batas waktu max :  ")[1]?.split(")   Terimakasih")[0] 
        //due time for transfer
        var current = new Date();
        var month = ((current.getMonth()+1)<10 ? '0':'')+(current.getMonth()+1)
        var hours = (current.getHours() < 10 ? '0':'')+current.getHours() ;
        var minutes = (current.getMinutes() < 10 ? '0':'')+current.getMinutes() ;
        var date = current.getFullYear()+'-'+month+'-'+(current.getDate()+1);
        var time = hours + ":" + minutes;
        var dateTimeDue = date+' '+time;

        // --------------------------------------
        console.log('buy response ---',response)
        console.log('time due ---',dateTimeDue)
        console.log('time ',time)

        if(!response.success){
            console.log("message success",response.success)
        }else {
            const buydata = {
                payment_method:paymentMethod,
                public_address:dataKepengOrderBuy?.public_address,
                quantity: dataKepengOrderBuy?.amount_kpg,
                rate:dataKepengOrderBuy?.rate,
                convertKpgToIDR:dataKepengOrderBuy?.convertKpgToIDR,
                status:response?.statusPayment,
                VANumber:response?.virtualAccountNumber,
                total:response?.total,
                url:response?.url,
                time:response?.time,
                bankLogo:paymentMethod?.payment_logo?.data,
                transaction_id:response?.transaction_id

            }
            console.log('----------checkbox',checked)

            console.log('buy data from response',buydata)
            // localStorage.setItem('buy-kepeng', JSON.stringify(buydata))
            localStorage.setItem('kepeng-transaction', JSON.stringify(buydata))
            handleClick(response?.transaction_id)
        }
        // else{
        //     toast.error('Please check to confirm the transaction')
        // }
    }
}

    return (
        <div>
            <NavbarSell/>
            <Container className="Payment-Method-Buy-Container">
             <Row className="mainRowBuyPaymentMethod px-4">
                    <Col className="mainrow-paymentmethod" xs={8}>
                    <Form>
                    <Form.Label className="paymentmethodlabelBuy">Payment Method (3/3)</Form.Label>
                    <Form.Text className="choosepaymentmethodBuy">
                    Please select a payment method                  </Form.Text>
                    </Form>
                        <Row className='e-wallet-letters-row'>
                            <h2 className='e-wallet-letters'>E-Wallet (Instant)</h2>
                        </Row>

                        <Row  className='qris-payment-method-row'>
                            <Row ref={btnRef} onClick={()=> handleSelectPayment('QRISPAY')} 
                            className={paymentMethod === 'QRISPAY' ? 'qris-payment-method activeLock': 'qris-payment-method'}>
                               <span className='qris-info-wrapper'>
                                <img className='qris-logo' src={QrisLogo} alt="/" />
                               <span className='type-of-ewallet'>QRIS (Gopay, OVO, Dana, LinkAja, ShopeePay)</span></span>    
                                       
                     
                            </Row>
                        </Row>

                        <Row className='virtual-account-title-row'>
                            <h2 className='virtual-account-letters'>Virtual Account & Bill (Instant)</h2>
                        </Row>
                       
                        <Row className='virtual-account-payment-method-row'>
                        {listPayement?.['virtual account']?.map((payment)=>{
                            if(payment?.payment_code === 'PERMATAVAC'){
                                return
                            }
                           return <div key={payment?.payment_code}
                            >
                            <Row ref={btnRef} onClick={()=> handleSelectPayment(payment?.payment_code)} 
                                    key={payment?.payment_code} className={paymentMethod === payment?.payment_code ? 'bri-virtual-account-payment-method activeLock': 'bri-virtual-account-payment-method'}>
                                    <span className='bri-virtual-account-info-wrapper'> 
                                    <img className='bri-logo' src={payment?.payment_logo} alt="/" />
                                    <span className='type-bri-virtual-account'>{payment?.payment_name}</span></span>
                             
                            </Row>
                            

                            </div>
                            
                        })}
                            
                        {/* 
                            <Row ref={btnRef} onClick={()=> handleSelectPayment('bca')} 
                            className={payment_method === 'bca' ? 'bca-payment-method activeLock': 'bca-payment-method'}>
                               <span className='bca-info-wrapper'><img className='bca-logo' src={BCALogo} alt="/" />
                               <span className='type-bca-virtual-account'>BCA Virtual Account</span></span>            
                               
                            </Row>

                            <Row ref={btnRef} onClick={()=> handleSelectPayment('BNIVA')} 
                            className={payment_method === 'BNIVA' ? 'bni-payment-method activeLock': 'bni-payment-method'}>
                               <span className='bni-virtual-account-info-wrapper'><img className='bni-logo' src={BNILogo} alt="/" />
                               <span className='type-bni-virtual-account'>BNI Virtual Account</span></span>            
                               
                            </Row>

                            <Row ref={btnRef} onClick={()=> handleSelectPayment('MANDIRIVA')} 
                            className={payment_method === 'MANDIRIVA' ? 'mandiri-payment-method activeLock': 'mandiri-payment-method'}>
                               <span className='mandiri-virtual-account-info-wrapper'> <img className='mandiri-logo' src={MandiriLogo} alt="/" />
                               <span className='type-mandiri-virtual-account'>Mandiri Bill</span></span>            
                               
                            </Row> */}
                        </Row>
                        <Row className='virtual-account-title-row'>
                            <h2 className='virtual-account-letters'>Modern Store</h2>
                        </Row>
                        <Row className='virtual-account-payment-method-row'>
                        {listPayement?.['modern store']?.map((payment, i)=>{
                            
                           return <div id={i}
                            >
                            <Row ref={btnRef} onClick={()=> handleSelectPayment(payment?.payment_code)} 
                                   key={payment?.payment_code}  className={paymentMethod === payment?.payment_code ? 'bri-virtual-account-payment-method activeLock': 'bri-virtual-account-payment-method'}>
                                    <span className='bri-virtual-account-info-wrapper'> 
                                    <img className='bri-logo' src={payment?.payment_logo} alt="/" />
                                    <span className='type-bri-virtual-account'>{payment?.payment_name}</span></span>
                             
                            </Row>

                            </div>
                            
                        })}
                            

                        </Row>

                        
                    </Col> 
                    <Col className="col_ordersummary" xs={5}>
                    <Form>


                    <Form.Label className="ordersummaryBuy">Order Summary</Form.Label>
                    <Form.Text className="conversionidrBuy">
                        Buy - {dataKepengQuantity?.quantity} KPG <span><NumberFormat 
                            value={dataKepengQuantity?.convertKpgToIDR}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR </span>
                    </Form.Text>
                    <Form.Text className="fee">
                       Admin fee <span><NumberFormat 
                            value={dataKepengQuantity?.adminFee}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR </span>
                    </Form.Text>
                    <Form.Text className="fee">
                       Platform fee <span><NumberFormat 
                            value={dataKepengQuantity?.platformFee}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR </span>
                    </Form.Text> 

                    <Form.Text className="totalrecieveBuy">
                       Total bill <span><NumberFormat 
                            value={dataKepengQuantity?.totalBill}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                            &nbsp;IDR  </span>
                    </Form.Text>
                    <Form>
     
                    <Form.Check 
                        className='checkbox-notallow-moneylaundry py-4'
                        type='checkbox'
                        onChange={()=>setChecked(true)}
                        label="By ticking, you are confirming this transaction is not for Money Laundering"
                        required
                    />
                    
                    </Form>

                    <Row className="allbuttonrowBuy">
                        <Row className="eachbtnBuy">

                            <Button className="buttonnextBuy" onClick={onSubmit} variant="primary" type="button">
                                Next
                            </Button>

                        </Row>

                        <Row className="eachbtnrowBuy">

                            <Link to='/receiver-info' className="buttonbackBuy" variant="light" type="submit">
                                Back
                            </Link>

                        </Row>
                        <Row>

                    
                        {
                        showAlert?<Alert className="nothavewalletBuy" variant="danger">
                        <img className='alertLogoBuy'
                            src={AlertLogo}
                            alt="/"
                        />
                        <p className="nothavewallettextBuy">
                        Please choose your payment method  
                        </p>

                        </Alert>:null
                        }
                    </Row>
                        <ToastContainer/>

                    </Row>
                   
                    </Form>
                  
                    </Col>
              

            </Row>
            <Footer/>
            <ToastContainer/>
        </Container>
        </div>
    );
}

export default PaymentMethodBuy;