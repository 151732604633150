import React, { useCallback, useEffect, useState } from 'react';
import './DetailTransactionSell.css'
import copyIcon from '../../assets/copy-icon.png'
import NavbarHomepage from '../../Homepage/Navbar'
import FooterDetailTransactionHistory from '../DetailHistoryTransactionFooter/DetailHistoryTransactionFooter'
import NumberFormat from 'react-number-format';
import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap';
import NavbarSell from '../../Sell/Navbar/NavSell';
import Footer from '../../Sell/Footer/Footer';
import { Link, useParams, useRoutes } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BriLogo from '../../assets/bri.svg'
import { getDetailHistoryTransaction } from '../../../services/Api';
import QRCode from 'qrcode.react'



function DetailHistoryTransactionSell() {
        const [copied, setCopied] = useState(false)
        const [textCopied, setTextCopied] = useState('Copy')

        const [copiedId, setCopiedId] = useState(false)
        const [textCopiedIdTransaction, setTextCopiedIdTransaction] = useState('Copy')



        const [statusWaiting, setStatusWaiting] = useState(false)
        const [onProgress, setOnProgress] = useState(false)
        const [statusComplete, setStatusComplete] = useState(false)
        const [openQR, setOpenQR] = useState(false)


        const [transfer_to, setTransfer_to] = useState('');
        //   const {query,isReady} =useRoutes();
        const params = useParams();
        console.log(params);

        const [dataDetailHistory, setDataDetailHistory] = useState('')
        console.log('status', dataDetailHistory)
        //   const thisProduct = dataDetailHistory.find(prod => prod.code === thisDetailId)


        const getDetailTransaction = useCallback(async (id) => {
                const dataHistory = await getDetailHistoryTransaction(id)
                setDataDetailHistory(dataHistory)
                //   console.log(dataHistory)
                //   console.log('check value',dataHistory?.[0].transfer_to)
        }, [getDetailHistoryTransaction])


        useEffect(() => {


                getDetailTransaction(params.id)
                //   console.log('data transfer to',dataDetailHistory?.[0]?.transfer_to)
                //   console.log('data transfer to',dataDetailHistory?.[0]?.exp_date)



        }, [])
        useEffect(() => {
                console.log('data transfer to', dataDetailHistory?.[0]?.transfer_to)
                setTransfer_to(dataDetailHistory?.[0]?.transfer_to)
                if (dataDetailHistory?.[0]?.status === "on progress") {
                        setOnProgress(true)
                } else if (dataDetailHistory?.[0]?.status === "waiting for transfer" || dataDetailHistory?.[0]?.status === "expired") {
                        setStatusWaiting(true)
                } else if (dataDetailHistory?.[0]?.status === "complete") {
                        setStatusComplete(true)
                        setOnProgress(false)
                }
                if (copied === true) {
                        setTextCopied('Copied')
                        setTimeout(() => {
                                setCopied(false)
                        }, 3000)
                } else {
                        setTextCopied('Copy')
                }
                if (copiedId === true) {
                        setTextCopiedIdTransaction('Copied')
                        setTimeout(() => {
                                setCopiedId(false)
                        }, 3000)
                } else {
                        setTextCopiedIdTransaction('Copy')
                }

                console.log('status', dataDetailHistory?.[0]?.status)

        })

        return (

                <div>
                        <NavbarHomepage />
                        <Container className="contain-all-history-detail-transaction-sell">
                                <Row className="headerRow-detail-history-transaction-buy">
                                        <Col className="headerCol" xs={6}>
                                                <Form.Label className="tokentransferlabel"> Transfer Token
                                                        <Row className='bri_logo_history_transaction justify-content-between'>
                                                                {/* <img src={BriLogo} alt="/" className='briLogoDetailHistoryTransaction'/> */}
                                                        </Row>
                                                </Form.Label>

                                        </Col>

                                </Row>
                                <Row className="bodyRow-detail-history-transaction-Buy" >
                                        <Col className="bodyCol" xs={6}>
                                                <Form.Label className="finishTransferBeforeLabel">Finish Transfer Before</Form.Label>
                                                <Form.Label className="transfertime">{dataDetailHistory?.[0]?.exp_date}</Form.Label>
                                                {dataDetailHistory?.[0]?.status === "on progress" &&
                                                        <Form.Label className="sendtopublicaddresslabel">Send your token to this public address
                                                        </Form.Label>}
                                                {dataDetailHistory?.[0]?.status === "on progress" &&
                                                        <Form.Label className="user-public-address-sell">{transfer_to?.length > 30 ? transfer_to.substring(0, 30) + "..." : transfer_to}
                                                                <CopyToClipboard text={transfer_to}
                                                                        onCopy={() => setCopied(true)}
                                                                >
                                                                        <span className="spanlogo-public-address">
                                                                                <img className="logocopy" src={copyIcon} alt="" /><span className="copy">{textCopied}</span>
                                                                        </span>
                                                                </CopyToClipboard>
                                                        </Form.Label>}
                                                {dataDetailHistory?.[0]?.status === "on progress" &&
                                                        <Form.Label className="user-public-address-sell-mobile">{transfer_to?.length > 15 ? transfer_to.substring(0, 10) + "..." : transfer_to}
                                                                <CopyToClipboard text={transfer_to}
                                                                        onCopy={() => setCopied(true)}
                                                                >
                                                                        <span className="spanlogo-public-address">
                                                                                <img className="logocopy" src={copyIcon} alt="" /><span className="copy">{textCopied}</span>
                                                                        </span>
                                                                </CopyToClipboard>
                                                        </Form.Label>}
                                                {dataDetailHistory?.[0]?.status === "on progress" &&
                                                        <Form.Label className="user-public-address-sell-ipad-large">{transfer_to?.length > 20 ? transfer_to.substring(0, 20) + "..." : transfer_to}
                                                                <CopyToClipboard text={transfer_to}
                                                                        onCopy={() => setCopied(true)}
                                                                >
                                                                        <span className="spanlogo-public-address">
                                                                                <img className="logocopy" src={copyIcon} alt="" /><span className="copy">{textCopied}</span>
                                                                        </span>
                                                                </CopyToClipboard>
                                                        </Form.Label>}

                                                {dataDetailHistory?.[0]?.status === "on progress" &&
                                                        <Form.Label className="user-public-address-sell-ipad">{transfer_to?.length > 20 ? transfer_to.substring(0, 10) + "..." : transfer_to}
                                                                <CopyToClipboard text={transfer_to}
                                                                        onCopy={() => setCopied(true)}
                                                                >
                                                                        <span className="spanlogo-public-address">
                                                                                <img className="logocopy" src={copyIcon} alt="" /><span className="copy">{textCopied}</span>
                                                                        </span>
                                                                </CopyToClipboard>
                                                        </Form.Label>}
                                                {dataDetailHistory?.[0]?.status === "on progress" &&
                                                        <a className="open-Payment-Detail-Href" target="_blank"
                                                                onClick={() => {
                                                                        setOpenQR(true)
                                                                }}
                                                        > Open QR Code </a>
                                                }
                                                {
                                                        openQR && <div id="id01" class="modalQR">
                                                                <div class="modalBackground">
                                                                        <div class="modalContainer">
                                                                                <div className="body">
                                                                                        <QRCode value={transfer_to} className="qrcode" />,
                                                                                </div>
                                                                                <Row className="modalbody-row">
                                                                                        <Form.Label className="address-modal">{transfer_to?.length > 20 ? transfer_to.substring(0, 20) + "..." : transfer_to}
                                                                                                <CopyToClipboard text={transfer_to}
                                                                                                        onCopy={() => setCopied(true)}
                                                                                                >
                                                                                                        <span className="spanlogo-public-address">
                                                                                                                <img className="logocopy-modal" src={copyIcon} alt="" /><span className="copy">{textCopied}</span>
                                                                                                        </span>
                                                                                                </CopyToClipboard>

                                                                                        </Form.Label>
                                                                                </Row>
                                                                                <Row className="modalFooter-row">
                                                                                        <Button className="btn-back-modal" onClick={() => { setOpenQR(false) }}>Back</Button>
                                                                                </Row>


                                                                        </div>
                                                                </div>
                                                        </div>
                                                }

                                                <Form.Label className="transfel-nominal-label">Transfer Nominal </Form.Label>
                                                <Form.Label className="total-nominal-kpg">{dataDetailHistory?.[0]?.amount_kpg} KPG</Form.Label>
                                                {/* <Row className="allbuttonrowSellHistory">
                    <Button className="button-input-hash-sell-history"  variant="primary" type="button">
                    Input transaction hash
                    </Button>

                    </Row> */}
                                                {/* <Form.Text className="find-hash">Input transaction hash to confirm your transfer.<span className="transaction-hash-link"><a>Where we can find transaction hash?</a></span></Form.Text> */}





                                                <hr className="line" />

                                                <Form.Label className="transfer-status">Transfer Status </Form.Label>
                                                {
                                                        statusWaiting ?
                                                                <Alert className="On-progress-alert-history-sell" variant="warning">
                                                                        {dataDetailHistory?.[0]?.status}
                                                                </Alert> : null
                                                }
                                                {
                                                        statusComplete ?
                                                                <Alert className="On-progress-alert-complete" variant="warning">
                                                                        {dataDetailHistory?.[0]?.status}
                                                                </Alert> : null
                                                }
                                                {
                                                        onProgress ? <Alert className="On-progress-alert-sell" variant="warning">
                                                                {dataDetailHistory?.[0]?.status}
                                                        </Alert> : null
                                                }


                                                <Form.Label className="transfer-status">Transaction Type </Form.Label>
                                                <Alert className="sell-alert-history" variant="success">{dataDetailHistory?.[0]?.transaction_type}</Alert>

                                                <Form.Label className="id-transaction-label">Id Transaction </Form.Label>
                                                <Form.Label className="id-transaction-history-sell-detail">{params.id}
                                                        <CopyToClipboard text={params.id}
                                                                onCopy={() => setCopiedId(true)}
                                                        >
                                                                <span className="spanlogo-id-transaction">
                                                                        <img className="logocopy" src={copyIcon} alt="" /> {textCopiedIdTransaction}
                                                                </span>
                                                        </CopyToClipboard>
                                                </Form.Label>





                                                {/* </Form.Label>
                    <Form.Label className="id-transaction-mobile" id="id-transaction-mobile">{ transactionId?.length > 15 ? transactionId.substring(0,15)+"...":transactionId} 
                   
                    <span className="spanlogo-id-transaction-mobile">
                            
                            <img  className="logocopy" src={copyIcon} alt="" /> 
                            <span className="copy">Copy</span> 
                    </span>
                  
                    </Form.Label> */}

                                                {/* <img  className="logocopy" src={copyIcon} alt="" />
                    <Form.Text className="copy-public-address">Copy</Form.Text> */}


                                                {/* <hr className="line"  />
                    <p>Anda dapat melakukan transfer KPG dengan mengikuti langkah berikut: </p>
                        <h4>Cara pembayaran via ATM BRI</h4>
                    <ol>
                    <li>Pada menu utama, pilih Transaksi Lain</li>
                    <li>Pilih Pembayaran</li>
                    <li>Pilih Lainnya</li>
                    <li>Pilih BRIVA</li>
                    <li>Masukkan Nomor BRIVA 124129357058596749</li>
                    <li>Jumlah pembayaran, 
                            nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                            Jika informasi yang dicantumkan benar,pilih kirim</li>
                    <li>Pembayaran telah selesai. Simpan bukti pembayaran anda.</li>
                    </ol>
                    <h4>Cara pembayaran via Internet Bank BRI</h4>
                    <ol>
                    <li>Masuk pada Internet Banking BRI</li>
                    <li>Pilih menu Pembayaran dan pembelian</li>
                    <li>Pilih sub menu BRIVA</li>
                    <li>Masukkan Nomor BRIVA 124129357058596749</li>
                    <li>Jumlah pembayaran, 
                            nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                            Jika informasi yang dicantumkan benar,pilih kirim</li>
                    <li>Masukan password dan mToken, pilih kirim</li>
                    <li>Pembayaran telah selesai. Simpan bukti pembayaran anda. Untuk mencetak bukti transaksi, pilih Cetak</li>
                    </ol>
                    <h4>Cara pembayaran via BRI mobile</h4>
                    <ol>
                    <li>Masuk ke dalam aplikasi BRI Mobile, pilih Mobile Banking BRI</li>
                    <li>Pilih Pembayaran, lalu pilih BRIVA</li>
                    <li>Masukkan Nomor BRIVA 124129357058596749</li>
                    <li>Jumlah pembayaran, 
                            nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                            Jika informasi yang dicantumkan benar,pilih Continue</li>
                    <li>Masukkan Mobile Banking BRI PIN, pilih Ok</li>
                    <li>Pembayaran telah selesai. Simpan notifikasi sebagai bukti pembayaran</li>
                    </ol> */}




                                        </Col>
                                        <Link to='/' className="back-to-homepage">
                                                <u>
                                                        Back to homepage
                                                </u>
                                        </Link>
                                </Row>

                        </Container>
                        <FooterDetailTransactionHistory />

                </div>
        )
}

export default DetailHistoryTransactionSell