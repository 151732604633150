import React, { useCallback, useEffect, useState } from 'react';
import './InputNominal.css'
import alertLogo from '../../assets/alertlogo.png'
import { Container, Form, Row, Col, Button,Alert } from 'react-bootstrap';
import NavbarSell from '../Navbar/NavSell';
import Footer from '../Footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import NumberFormat from "react-number-format";
import axios from "axios";
import { checkRatesApi } from '../../../services/Api';



function InputNominal() {
    const [quantity,setquantity] = useState('')
    const [rateKpgSell,setRateKpgSell]= useState()
    const [show, setShow] = useState(false);
    const [sellData,setSellData] = useState([])
    const moveToInputPublicAddressPage = useNavigate()

    const convertKpgToIDR = quantity*rateKpgSell
    const adminFee = convertKpgToIDR*2.5/100;
    const totalRecieve = convertKpgToIDR - adminFee




    
    const getKpgRatesSell = useCallback(async() => {
        const rateData = await checkRatesApi();
        setRateKpgSell(rateData.sell)
        console.log(rateData)

    },[checkRatesApi])

    

    function handledClick(){
        // moveToInputPublicAddressPage('/seller-bank-info')
        moveToInputPublicAddressPage('/seller-info')

    }
    useEffect(()=>{

        getKpgRatesSell()
        // const dataSellFromLocal = localStorage.getItem('sell-transaction')
        const dataSellFromLocal = localStorage.getItem('kepeng-transaction')

        const dataSell = JSON.parse(dataSellFromLocal)
        setSellData(dataSell)
    },[])

    const onSubmit = () =>{
  
        if(quantity === ''){
            console.log('please enter input')
            setShow(true)


        }else{
            const data ={
                transaction_type:sellData?.transaction_type,
                quantity,
                convertKpgToIDR,
                rate:rateKpgSell,
                adminFee,
                totalRecieve
            }
            // localStorage.setItem('sell-transaction',JSON.stringify(data))
            localStorage.setItem('kepeng-transaction',JSON.stringify(data))

            console.log(data)
            console.log("data quantity" , data?.quantity)
            console.log("data rate", data?.rateKpgSell)
            handledClick()
        }
    }

    return (
        <div>
        <NavbarSell/>
        <Container className="containallitem">
            <Form>
             <Row className="mainRow_sell">
                    <Col className="row-input-kepeng-quantity" xs={8}>
                    <Form.Label className="sellkepeng">Sell Kepeng (KPG) (1/2)</Form.Label>
                    <Form.Text className="fillkepeng">
                        Fill the Kepeng nominal that you want to sell
                    </Form.Text>
                    <Form.Group>
                        <Form.Label className="nominal">Nominal</Form.Label>
                        <Form.Control 
                            type="number" 
                            placeholder="Input Kepeng Nominal"
                            value={quantity} 
                            onChange={(event)=>setquantity(event.target.value)}
                            required />
                        <Row>
                        {
                        show?<Alert className="nothavewallet" variant="danger">
                        <img  className="alertlogo" src={alertLogo}/>
                        <p className="nothavewallettext">
                        Please input kepeng nominal  
                        </p>

                        </Alert>:null
                        }
                    </Row>   
                    <Form.Text className="totalidr d-flex flex-row">
                    Sell Rate <strong>&nbsp;Kepeng</strong><strong> &nbsp; (1,00 KPG &nbsp;  = &nbsp;  <NumberFormat 
                        value={rateKpgSell} 
                        displayType="text" 
                        thousandSeparator=","
                        decimalSeparator="."
                        />  )</strong> 
                        </Form.Text>

                        <Form.Text className="totalidr">
                        Total (Rupiah - IDR)
                        </Form.Text>
                        <Form.Label className="kepengtoidr">
                        <NumberFormat 
                        value={convertKpgToIDR} 
                        displayType="text" 
                        thousandSeparator=","
                        decimalSeparator="."
                        />  &nbsp;IDR </Form.Label>
                        
                  
                    </Form.Group>
                  
                    
                    </Col>
                    <Col className='info_nominal' xs={5}>
                    <Form.Label className="ordersummary">Order Summary</Form.Label>
                    <Form.Text className="conversionidr">
                        Sell - {quantity} KPG <span><NumberFormat 
                        value={convertKpgToIDR} 
                        displayType="text" 
                        thousandSeparator=","
                        decimalSeparator="."
                        /> 
                        &nbsp;IDR  </span>
                    </Form.Text>
                    <Form.Text className="fee">
                       Admin fee <span><NumberFormat 
                            value={adminFee}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR </span>
                    </Form.Text>
                    <Form.Text className="totalrecieve">
                       Total Recieve <span><NumberFormat 
                        value={totalRecieve} 
                        displayType="text" 
                        thousandSeparator=","
                        decimalSeparator="."
                        /> 
                        &nbsp;IDR  </span>
                    </Form.Text>
                    <Row className="allbuttonrow">
                        <Row className="eachbtn">
                        {/* <Link to="/input-public-address"  className="buttonnext"> */}

                            <Button 
                            className="buttonnext" 
                            type="button"
                            onClick={onSubmit}
                            >
                                 Next                        

                            </Button>
                        {/* </Link> */}


                        </Row>
                        <Row className="eachbtnrow">
                        <Link to="/"  className="buttonback">
                            {/* <Button 
                            className="buttonback" 
                            variant="light" 
                            type="submit"> */}
                                Back
                            {/* </Button> */}
                        </Link>

                        </Row>
                    </Row>
                    </Col>
                   
            </Row>
            </Form>
                
                         
        </Container>
        <Footer/>
        </div>

    );
}

export default InputNominal;