import React, { useCallback, useEffect, useState } from 'react';
import './SellTrasnsactionDetail.css'
import copyIcon from '../../assets/copy-icon.png'
import QRCode from 'qrcode.react'


import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap';
import Navbar from '../../Homepage/Navbar';
import NavbarSell from '../Navbar/NavSell';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getDetailHistoryTransaction } from '../../../services/Api';



function SellTransactionDetail() {
        const transactionId = "0xc98a8ec7a07f1b743e86896a5243"
        const [copied, setCopied] = useState(false)
        const [openQR, setOpenQR] = useState(false)
        const [statusWaiting, setStatusWaiting] = useState(false)
        const [onProgress, setOnProgress] = useState(false)
        const [statusComplete, setStatusComplete] = useState(false)
        const [dataDetailHistory, setDataDetailHistory] = useState('')



        const getDetailTransaction = useCallback(async (id) => {
                const dataHistory = await getDetailHistoryTransaction(id)
                setDataDetailHistory(dataHistory)
                //   console.log(dataHistory)
                //   console.log('check value',dataHistory?.[0].transfer_to)
        }, [getDetailHistoryTransaction])


        const [sellKPG, setSellKPG] = useState({
                quantity: 0,
                convertKpgToIDR: 0

        })

        const [sellPublicAddress, setSellPublicAddress] = useState({
                public_address: ''
        })

        useEffect(() => {
                const nominalKpgFromLocal = localStorage.getItem('sell-transaction')
                const publicAddressFromLocal = localStorage.getItem('public-address-seller')

                const dataNominalKpg = JSON.parse(nominalKpgFromLocal)
                const datapublic_address = JSON.parse(publicAddressFromLocal)



                setSellKPG(dataNominalKpg)
                setSellPublicAddress(datapublic_address)


        }, [])

        useEffect(() => {
                if (sellKPG?.status === "on progress") {
                        setOnProgress(true)
                } else if (sellKPG?.status === "waiting for transfer") {
                        setStatusWaiting(true)
                } else if (sellKPG?.status === "complete") {
                        setStatusComplete(true)
                }

                console.log('status', sellKPG?.status)
        })

        //due time for transfer

        return (
                <div>
                        <NavbarSell />
                        <Container className="contain-all-detail-transaction">
                                <Row className="headerRow-detailSell">
                                        <Col className="headerCol" xs={6}>
                                                <Form.Label className="tokentransferlabel"> Transfer Token </Form.Label>
                                        </Col>
                                </Row>
                                <Row className="bodyRow-detailSell" >
                                        <Col className="bodyCol" xs={6}>
                                                <Form.Label className="finishTransferBeforeLabel">Finish Transfer Before</Form.Label>
                                                <Form.Label className="transfertime">{sellKPG?.time}</Form.Label>
                                                <Form.Label className="sendtopublicaddresslabel">Send your token to this public address </Form.Label>

                                                <Form.Label className="user-public-address-sell">{sellKPG?.transfer_to?.length > 30 ? sellKPG?.transfer_to.substring(0, 30) + "..." : sellKPG?.transfer_to}
                                                        <CopyToClipboard text={sellKPG?.transfer_to}
                                                                onCopy={() => setCopied(true)}
                                                        >
                                                                <span className="spanlogo-public-address">
                                                                        <img className="logocopy" src={copyIcon} alt="" /><span className="copy">Copy</span>
                                                                </span>
                                                        </CopyToClipboard>
                                                </Form.Label>

                                                <Form.Label className="user-public-address-sell-mobile">{sellKPG?.transfer_to?.length > 20 ? sellKPG?.transfer_to.substring(0, 15) + "..." : sellKPG?.transfer_to}

                                                        <CopyToClipboard text={sellKPG?.transfer_to}
                                                                onCopy={() => setCopied(true)}
                                                        >
                                                                <span className="spanlogo-public-address-mobile">
                                                                        <img className="logocopy" src={copyIcon} alt="" />
                                                                        <span className="copy">Copy</span> </span>
                                                        </CopyToClipboard>
                                                </Form.Label>

                                                <Form.Label className="user-public-address-sell-ipad">{sellKPG?.transfer_to?.length > 20 ? sellKPG?.transfer_to.substring(0, 15) + "..." : sellKPG?.transfer_to}

                                                        <CopyToClipboard text={sellKPG?.transfer_to}
                                                                onCopy={() => setCopied(true)}
                                                        >
                                                                <span className="spanlogo-public-address-ipad">
                                                                        <img className="logocopy" src={copyIcon} alt="" />
                                                                        <span className="copy">Copy</span> </span>
                                                        </CopyToClipboard>
                                                </Form.Label>
                                                <a className="open-Payment-Detail-Href" target="_blank"
                                                        onClick={() => {
                                                                setOpenQR(true)
                                                        }}
                                                > Open QR Code </a>
                                                {
                                                        openQR && <div id="id01" class="modalQR">
                                                                <div class="modalBackground">
                                                                        <div class="modalContainer">
                                                                                <div className="body">
                                                                                        <QRCode value={sellKPG?.transfer_to} className="qrcode" />,
                                                                                </div>
                                                                                <Row className="modalbody-row">
                                                                                        <Form.Label className="address-modal">{sellKPG?.transfer_to?.length > 20 ? sellKPG?.transfer_to.substring(0, 20) + "..." : sellKPG?.transfer_to}
                                                                                                <CopyToClipboard text={sellKPG?.transfer_to}
                                                                                                        onCopy={() => setCopied(true)}
                                                                                                >
                                                                                                        <span className="spanlogo-public-address">
                                                                                                                <img className="logocopy-modal" src={copyIcon} alt="" /><span className="copy">Copy</span>
                                                                                                        </span>
                                                                                                </CopyToClipboard>

                                                                                        </Form.Label>
                                                                                </Row>
                                                                                <Row className="modalFooter-row">
                                                                                        <Button className="btn-back-modal" onClick={() => { setOpenQR(false) }}>Back</Button>
                                                                                </Row>


                                                                        </div>
                                                                </div>
                                                        </div>
                                                }


                                                <Form.Label className="transfel-nominal-label">Transfer Nominal </Form.Label>
                                                <Form.Label className="total-nominal-kpg">{sellKPG?.quantity} KPG</Form.Label>
                                                <Form.Label className="transfer-status">Transfer Status </Form.Label>
                                                {
                                                        onProgress ?
                                                                <Alert className="On-progress-alert-sell-waiting-for-transfer" variant="warning">
                                                                        {sellKPG?.status}
                                                                </Alert> : null
                                                }
                                                {
                                                        statusComplete ?
                                                                <Alert className="On-progress-alert-sell-complete" variant="warning">
                                                                        {sellKPG?.status}
                                                                </Alert> : null
                                                }
                                                {
                                                        statusWaiting ? <Alert className="On-progress-alert-sell" variant="warning">
                                                                {sellKPG?.status}
                                                        </Alert> : null
                                                }
                                                <hr className="line" />
                                                <Form.Label className="transfer-type">Transfer type </Form.Label>
                                                <Alert className="sell-alert" variant="danger">Sell</Alert>
                                                <Form.Label className="id-transaction-label">Id Transaction </Form.Label>

                                                <Form.Label className="id-transaction">{sellKPG?.transaction_id}
                                                        <CopyToClipboard text={sellKPG?.transaction_id}
                                                                onCopy={() => setCopied(true)}
                                                        >
                                                                <span className="spanlogo-id-transaction">
                                                                        <img className="logocopy" src={copyIcon} alt="" /> Copy
                                                                </span>
                                                        </CopyToClipboard>
                                                </Form.Label>

                                                <Form.Label className="id-transaction-mobile" id="id-transaction-mobile">{sellKPG?.transaction_id?.length > 20 ? sellKPG?.transaction_id.substring(0, 15) + "..." : sellKPG?.transaction_id}

                                                        <CopyToClipboard text={sellKPG?.transaction_id}
                                                                onCopy={() => setCopied(true)}
                                                        >
                                                                <span className="spanlogo-id-transaction-mobile">

                                                                        <img className="logocopy" src={copyIcon} alt="" />
                                                                        <span className="copy">Copy</span>
                                                                </span>
                                                        </CopyToClipboard>
                                                </Form.Label>

                                                <Form.Label className="id-transaction-ipad" id="id-transaction-ipad">{sellKPG?.transaction_id?.length > 25 ? sellKPG?.transaction_id.substring(0, 15) + "..." : sellKPG?.transaction_id}
                                                        <CopyToClipboard text={transactionId}
                                                                onCopy={() => setCopied(true)}
                                                        >
                                                                <span className="spanlogo-id-transaction-ipad">

                                                                        <img className="logocopy" src={copyIcon} alt="" />
                                                                        <span className="copy">Copy</span>
                                                                </span>
                                                        </CopyToClipboard>
                                                </Form.Label>

                                                {/* <hr className="line"  />
                    <p>Anda dapat melakukan transfer KPG dengan mengikuti langkah berikut: </p>
                        <h4>Cara pembayaran via ATM BRI</h4>
                    <ol>
                    <li>Pada menu utama, pilih Transaksi Lain</li>
                    <li>Pilih Pembayaran</li>
                    <li>Pilih Lainnya</li>
                    <li>Pilih BRIVA</li>
                    <li>Masukkan Nomor BRIVA 124129357058596749</li>
                    <li>Jumlah pembayaran, 
                            nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                            Jika informasi yang dicantumkan benar,pilih kirim</li>
                    <li>Pembayaran telah selesai. Simpan bukti pembayaran anda.</li>
                    </ol>
                    <h4>Cara pembayaran via Internet Bank BRI</h4>
                    <ol>
                    <li>Masuk pada Internet Banking BRI</li>
                    <li>Pilih menu Pembayaran dan pembelian</li>
                    <li>Pilih sub menu BRIVA</li>
                    <li>Masukkan Nomor BRIVA 124129357058596749</li>
                    <li>Jumlah pembayaran, 
                            nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                            Jika informasi yang dicantumkan benar,pilih kirim</li>
                    <li>Masukan password dan mToken, pilih kirim</li>
                    <li>Pembayaran telah selesai. Simpan bukti pembayaran anda. Untuk mencetak bukti transaksi, pilih Cetak</li>
                    </ol>
                    <h4>Cara pembayaran via BRI mobile</h4>
                    <ol>
                    <li>Masuk ke dalam aplikasi BRI Mobile, pilih Mobile Banking BRI</li>
                    <li>Pilih Pembayaran, lalu pilih BRIVA</li>
                    <li>Masukkan Nomor BRIVA 124129357058596749</li>
                    <li>Jumlah pembayaran, 
                            nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                            Jika informasi yang dicantumkan benar,pilih Continue</li>
                    <li>Masukkan Mobile Banking BRI PIN, pilih Ok</li>
                    <li>Pembayaran telah selesai. Simpan notifikasi sebagai bukti pembayaran</li>
                    </ol> */}




                                        </Col>
                                        <Link to='/' className="back-to-homepage">
                                                <u>
                                                        Back to homepage
                                                </u>
                                        </Link>
                                </Row>

                        </Container>

                        <Footer />
                </div>
        );
}

export default SellTransactionDetail;