import React, { useRef, useEffect, useCallback } from 'react';
import {Col, Row, Form, Container } from 'react-bootstrap';
import ExhangeLogo from "../assets/exchangeLogo.png"
import { useNavigate, Link, Router } from 'react-router-dom';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { checkRatesApi } from '../../services/Api';

const BuyAndSellCard = () => {

    const [inputKepengAmount, setInputKepengAmount]= useState('')
    const [inputIdrAmount, setInputIdrAmount]= useState('')
    const [rateKpgBuy,setRateKpgBuy]= useState()
    const [rateKpgSell,setRateKpgSell]= useState()
    const [transaction_typeSell,setTransactionTypeSell]= useState('sell')
    const [transaction_type,setTransactionType]= useState('')
    const [transaction_typeBuy,setTransactionTypeBuy]= useState('buy')


    const convertKpgToIDRBuy = inputKepengAmount*rateKpgBuy
    const convertKpgToIDRSell = inputKepengAmount*rateKpgSell
    const convertIdrtoKepeng = inputIdrAmount/rateKpgSell
    
    const buyKepengRate = rateKpgBuy

    const [showInvertInput, setshowInvertInput]= useState(true)
    const btnRef = useRef();

    const getKpgRatesBuy = useCallback(async() => {
        const rateData = await checkRatesApi();
        setRateKpgBuy(rateData?.buy)
        console.log(rateData)

    },[checkRatesApi])

    const getKpgRatesSell = useCallback(async() => {
        const rateData = await checkRatesApi();
        setRateKpgSell(rateData?.sell)
        console.log(rateData)

    },[checkRatesApi])


    
    useEffect(() =>{
        getKpgRatesBuy()
        getKpgRatesSell()
        // onSubmitSell()
        // onSubmitBuy()
        const showInvertInput = e => {
            if(btnRef && !btnRef.current.contains(e.target) ){
                setshowInvertInput('')
            }
            console.log(showInvertInput)
        };
       
        document.body.addEventListener('mouseDown', showInvertInput);
        return() => document.body.removeEventListener('mouseDown', showInvertInput);
    },[]);

    const handleChangeRate = () => {
        setshowInvertInput(!showInvertInput);
        console.log(showInvertInput);
    }

    const onSubmitBuy = () => {
        setTransactionType(transaction_typeBuy)
        console.log('type',transaction_type)
        const dataBuy ={
            transaction_type:transaction_typeBuy
        }

        // localStorage.setItem('buy-kepeng',JSON.stringify(dataBuy))
        localStorage.setItem('kepeng-transaction',JSON.stringify(dataBuy))

        console.log('data',dataBuy)
    }

    const onSubmitSell = () => {
        setTransactionType(transaction_typeSell)
        console.log('type',transaction_type)

        const dataSell ={
            transaction_type:transaction_typeSell
        }

        // localStorage.setItem('sell-transaction',JSON.stringify(dataSell))
        localStorage.setItem('kepeng-transaction',JSON.stringify(dataSell))
        console.log('data',dataSell)
    }

    // useEffect(()=>{
    //     onSubmitSell()
    //     onSubmitBuy()
    // },[])



  return (
    // <Container>
    <div className='buy-sell-card '>
        <div  className='container-card container-sm shadow-lg p-3 mb-5 bg-body rounded container_buy_sell' >
            <Col className='w-100'>
            <Row className='justify-content-between align-items-center mx-4 mt-5 changer_input_main_info_wrapper' >

             {!showInvertInput? (
                    <div className='changer__wrapper'>
                        <h5 className='word-amount-right text-left'>Konversi Rupiah</h5>
                            <div className="input-group mb-3">
                            <input type="number" className="form-control-2" 
                            placeholder="In IDR" aria-label="Recipient's username" 
                            aria-describedby="basic-addon2" value={inputIdrAmount}
                            onChange={(event)=>setInputIdrAmount(event.target.value)}/>
                            <span className="input-group-text" id="basic-addon-2">IDR</span>
                            </div>
                    </div>):(

                    <div className='changer__wrapper'>
                        <h5 className='word-amount-Left text-left'>Jumlah Kepeng</h5>
                            <div className="input-group mb-3">`
                            <input type="number" className="form-control-1" value={inputKepengAmount} 
                            placeholder="KPG Amount" aria-label="Recipient's username" 
                            aria-describedby="basic-addon2" onChange={(event)=>setInputKepengAmount(event.target.value)}/>
                            <span className="input-group-text" id="basic-addon-1">KPG</span>
                            </div>
                            {/* <h3 className='kepeng-to-idr'>1,00 KPG = 14.400 IDR</h3>
                            <h5 className='kepeng-to-idr-info'>Konversi Kepeng (KPG) ke Rupiah (IDR)</h5> */}
                    </div>)}

                    <div className="exchange-logo align-items-center changer__wrapper">
                    <img className='exchange-logo-img' onClick={()=> handleChangeRate()} src={ExhangeLogo} alt="/" />
            </div>
                    {!showInvertInput?
                (
                    <div className='changer__wrapper'>
                    <h5 className='word-amount-Left text-left'>Jumlah Kepeng</h5>
                    <div className="input-group mb-3">
                        <input type="number" className="form-control-1" value={convertIdrtoKepeng} 
                        placeholder="KPG Amount" aria-label="Recipient's username" 
                        aria-describedby="basic-addon2"/>
                        <span className="input-group-text" id="basic-addon-1">KPG</span>
                        </div>
                    </div>):

            (<div className='changer__wrapper'>
            <h5 className='word-amount-right text-left'>Konversi Rupiah</h5>
                    <div className="input-group mb-3">
                        <input type="number" className="form-control-2" 
                        placeholder="In IDR" aria-label="Recipient's username" 
                        aria-describedby="basic-addon2" dec value={convertKpgToIDRBuy}
                        displayType="text"
                        thousandSeparator=","
                        decimalSeperator='.'/>
                        <span className="input-group-text" id="basic-addon-2">IDR</span>
                    </div>
                    </div>)
                }

                {/* <div className='changer__wrapper'>
                <h5 className='word-amount-right'>Konversi Rupiah</h5>
                            <div className="input-group mb-3">
                            <input type="number" className="form-control-2" 
                            placeholder="In IDR" aria-label="Recipient's username" 
                            aria-describedby="basic-addon2" value={convertKpgToIDR}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                            <span className="input-group-text" id="basic-addon-2">IDR</span>
                            </div>
                </div> */}
               

            </Row>
            <Row className='justify-content-between mx-4 mt-2 changer_input_button_wrapper' >
                    <div className='changer__wrapper'>
                    <h3 className='kepeng-to-idr'>1,00 KPG &nbsp;= &nbsp;
                    <NumberFormat 
                            value={buyKepengRate}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/> 
                            &nbsp;IDR</h3>
                    <h5 className='kepeng-to-idr-info'>Konversi Kepeng (KPG) ke Rupiah (IDR)</h5>
                    </div>
                    
                   <div className='button-sell-buy-homepage changer__wrapper'>
                   <Link className='button-buy' to='/kpg-buy' value={transaction_type} onClick={onSubmitBuy} >Buy</Link>
                    <Link className='button-sell' to='/kpg-sell' value ={transaction_type} 
                    onClick={onSubmitSell}>
                        Sell</Link>
                    </div> 
                </Row>
            </Col>
                    
        </div>

     

    </div>
    // </Container>
  )
}


export default BuyAndSellCard


