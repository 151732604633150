import React from 'react'
import NavbarHomepage from '../Homepage/Navbar'
import FooterTransactionHistory from './FooterTransactionHistory/FooterTransactionHistory'
import HeroTransactionHistory from './HeroTransactionHistory/HeroTransactionHistory';
import Footer from '../Homepage/Footer'
const TransactionHistory = () => {
  return (
    <div className='transaction-history-wrapper'>
        <NavbarHomepage/>
        <HeroTransactionHistory/>
        <FooterTransactionHistory/>
        {/* <Footer/> */}
    </div>
  )
}

export default TransactionHistory