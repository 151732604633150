import React from 'react'
import NavbarHomepage from '../Homepage/Navbar'
import FooterDetailTransactionHistory from '../DetailHistoryTransaction/DetailHistoryTransactionFooter/DetailHistoryTransactionFooter'
import DetailHistoryTransactionBuy from './DetailHistoryTransactionBuy/DetailHistoryTransactionBuy'

const DetailHistoryTransaction = () => {
  return (
    <div className='detail-transaction-history-buy-wrapper'>
        <NavbarHomepage/>
        <DetailHistoryTransactionBuy/>
        <FooterDetailTransactionHistory/>
    </div>
  )
}

export default DetailHistoryTransaction