import React, {useEffect, useState} from 'react'
import Navbar from '../Homepage/Navbar'
import Form from 'react-bootstrap/Form';
import { Container,Row,Col } from 'react-bootstrap'
import './FinishVerificationStep.css'
import VerifyLogo from '../assets/verify.png'
import FooterVerifyAccount from '../VerifiedAccountChooseCountry/FooterVerifyAccount/FooterVerifyAccount';

const FinishVerificationStep = () => {
    const [dataKyc, setDataKyc] = useState([]);

    useEffect (() =>{
        const kycInfoFromLocal = localStorage.getItem('kyc-information')
        const kycInformation = JSON.parse(kycInfoFromLocal)
        setDataKyc(kycInformation)
    }, [])
  return (
    <div>
    <Navbar/>
    <div className='verification_finish_wrapper d-flex flex-column justify-content-center mx-auto'>
     <Container className='container_verification_finish_form d-flex flex-column justify-content-center align-items-center px-5'>

        <div className="verification_finish__form d-flex flex-column w-75">
             <Row>
                 <Col className='px-5 pb-5'>
                 <img src={VerifyLogo} alt="/" className='d-flex mx-auto mt-4' style={{width:'116px', height:'116px'}}/>
                     <h2 className='pt-3 d-flex justify-content-center' style={{fontSize:'32px', fontFamily:'inter', fontWeight:'500', color:'#2C3131'}}>Congratulations!</h2>

                     <h2 className='mt-1 justify-content-center mx-auto' style={{fontSize:'20px', fontWeight:'300', fontFamily:'inter', color:'#A9A9A9', textAlign:'center'}}>You’ve completed GrahaKPG verification account step. We will inform your verification status via email. Please wait a little bit more time. If you have any question, please contact us <a href='mailto:admin@grahakpg.com' target="_blank"><span style={{fontWeight:'500', color:'#34495E', textDecoration:'underline'}}>here</span></a>.</h2>
                     
                     <Row className='d-flex flex-row'>
                         <Col className='d-flex flex-row align-items-text justify-content-between'>

                         <a href='/' className='w-100 ps-3'><button  className='w-100 mt-4 button_next'>Back</button></a>

                         </Col>
                     </Row>

                 </Col>
             </Row>

         </div>
     </Container>
     <FooterVerifyAccount/>
     </div>
 </div>
  )
}

export default FinishVerificationStep