import React from 'react';
import './NavSell.css'
import { Navbar, NavDropdown, Container, Nav } from 'react-bootstrap';
import grahaLogo from '../../assets/GrahaKPG-logo.png'
import { Link } from 'react-router-dom';

function NavbarSell() {
    return (
            <Navbar className="navbar-sell" bg="bg-transparent" expand="lg">
                <Container className="navbarcontainer">
                <Navbar.Brand ><a href=""><Link to='/'><img className="grahaLogo" src={grahaLogo}/></Link></a></Navbar.Brand>
               {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                </Container>
            </Navbar>

            // <hr />      
    );
}

export default NavbarSell;