import React from 'react'
import Navbar from '../Homepage/Navbar'
import Form from 'react-bootstrap/Form';
import { Container,Row,Col } from 'react-bootstrap'
import './ForgotPassword.css'

const ForgotPassword = () => {
  return (
    <div>
       <Navbar/>
       <div className='forgot_password_wrapper d-flex flex-row justify-content-center mx-auto'>
        <Container className='container_forgot_password_form d-flex justify-content-center px-5'>
            <div className="forgot_password__form d-flex flex-column">
                <Row>
                    <Col className='px-5 pb-5'>
                        <h2 className=' mt-5' style={{fontSize:'32px',fontFamily:'inter', fontWeight:'500'}}>Forgot Password</h2>
                        
                        <h2 className=' ' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'300'}}>Enter your username or email to recover your password. You will receive an email with instructions. If you are having problems recovering your password <span style={{fontWeight:'700', color:'#34495E'}}>contact.</span></h2>
                        
                        <h2 className=' mt-4' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Email</h2>

                        <input className=' w-100 ps-3 mb-3 input_forgot_password' style={{height:'62px'}} type="text" placeholder='Your Email' />

                        <Row className='d-flex flex-row'>
                            <Col className='d-flex flex-row align-items-text justify-content-between'>
                            <a href='/signin_grahakpg' className='a_signin_forgot_pass w-100'><h2 className='my-auto my-auto w-100' style={{fontSize:'16px', fontWeight:'600', fontFamily:'inter', color:'#34495e'}}>Sign in now</h2></a>
                            <a href='/confirmation_register_grahakpg' className='a_signin_forgot_pass w-100'><h2 className='my-auto my-auto w-100' style={{fontSize:'16px', fontWeight:'600', fontFamily:'inter', color:'#34495e'}}>confirmation</h2></a>
                            
                            <h2 className='not_a_member d-flex my-auto w-100 justify-content-end'>Not a member? &nbsp;<a href='/register_grahakpg' className='a_not_member d-flex'><span style={{fontSize:'16px', fontWeight:'600', fontFamily:'inter', color:'#34495e'}}> Register</span></a> </h2>
                            </Col>
                        </Row>
                        

                        <button className='w-100 mt-4 button_forgot_password' >Send Instruction</button>

                        <a href='/changed_password_grahakpg'><button  className='w-100 mt-4 button_forgot_password' >changed password</button></a>

                    </Col>
                </Row>

            </div>
        </Container>
        </div>
    </div>
  )
}

export default ForgotPassword