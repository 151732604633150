import React from "react";
import { Dropdown } from "react-bootstrap";
import GrahaLogo from "../assets/graha_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import phoneContactLogo from "../assets/phone.png";
import emailContactLogo from "../assets/email.png";
import instagramLogo from "../assets/instagram.png";
import facebookLogo from "../assets/facebook.png";

const Navbar = () => {
  return (
    <nav className="navbar-homepage navbar navbar-expand-lg bg-dark sticky-top navbar_homepage">
      {/* <div className="container"> */}
      <a className="navbar-brand ps-5" href="/">
        <img className="logo" src={GrahaLogo} alt="/" href="/" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <FontAwesomeIcon
          icon={faBars}
          style={{ color: "#fff" }}
          className="pe-5"
        />
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto pe-5">
          <li className="nav-item active">
            <a className="nav-link" aria-current="page" href="/">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/token_smart_contract">
              Token Smart Contract
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link "
              aria-current="page"
              href="/transaction-history-detail"
            >
              Transaction History
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              About
            </a>
          </li>
          <li className="nav-item">
            {/* <a className="nav-link" href="#">About</a> */}
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Info
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Bantuan</Dropdown.Item>
                <Dropdown.Item
                  href="https://graha-kpg.gitbook.io/faq-grahakpg/"
                  target="_blank"
                >
                  FAQ
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">Blog</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Tutorial</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
      {/* </div> */}
    </nav>
  );
};

export default Navbar;
