import React from 'react'
import betamaxPhoneImage from '../assets/insideCard.png'
import { Container, Row, Col } from 'react-bootstrap'
import GooglePlayStoreLogo from '../assets/googleplay.png'
import AppStoreLogo from '../assets/appstore.png'
const Card = () => {
  return (
    <div className='card-wrapper'>
    <Container className='container px-4 h-auto'>
      <Row className='card-main-info d-flex flex-row px-2 w-auto'>
        {/* <div className="card-main-info container-fluid px-4 d-flex flex-row w-auto"> */}
        <Col className='d-flex flex-column card_info_left'>
            <h1 className='card-text-1 w-auto'>Make sure you have a <span className='betamax-text-bold'>Betamax account</span> or <span className='betamax-text-bold'>Decentralized Wallet</span> to be able to make KPG transaction </h1>
           {/* <a href='https://wallet.betamax.co/'target="_blank"><button className='button-create-betamax'>Create Betamax Account</button></a>  */}
           <Row className='d-flex flex-row mt-4'>
           <a href='https://play.google.com/store/apps/details?id=com.betamax.mobile&hl=in&gl=US' target='_blank' className='storeApp_button' style={{width:'250px'}}> <img className='google-play-store-logo' src={GooglePlayStoreLogo} alt="/" /></a>
           <img className='app-store-logo ' src={AppStoreLogo} alt="/" />
           </Row>
           
        </Col>
        <Col className='w-50 d-flex flex-row img_card_right'>
            <img className='betamax-phone-img d-flex flex-row w-100 ms-4' src={betamaxPhoneImage} alt="/" />
        </Col>
            {/* </div> */}
      </Row>
    </Container>
    </div>
  )
}

export default Card