import React, { useCallback,useEffect, useState } from 'react';
import './BankAccount.css'
import alertLogo from '../../assets/alertlogo.png'
import { ToastContainer, toast } from 'react-toastify';

import { Container, Form, Row, Col, Button,Alert } from 'react-bootstrap';
import NavbarSell from '../Navbar/NavSell';
import Footer from '../Footer/Footer';
import NumberFormat from "react-number-format";
import { Link, useNavigate } from 'react-router-dom';
import { checkRatesApi, SellApi, sellKepeng } from '../../../services/Api';



function BankAccount() {
    const [bank,setBank] = useState('')
    const [account_number,setaccount_number] = useState('')
    const [account_name,setaccount_name] = useState('')
    const [show, setShow] = useState(false);
    const [rateKpgSell,setRateKpgSell]= useState()
    const [checked, setChecked]= useState(false)


    const moveToSellDetailTransaction = useNavigate()
    function handledClick(txId){
        
        moveToSellDetailTransaction(`/detail_transaction_sell/${txId}`)
    }



    const [dataKepengQuantity,setDataKepengQuantity] = useState({
        quantity:0,
        convertKpgToIDR:0,
    })
    const [sellPublicAddress,setSellPublicAddress] = useState({
        sellerPublicAddress:''
    })
    const getKpgRatesSell = useCallback(async() => {
        const rateData = await checkRatesApi();
        setRateKpgSell(rateData.sell)
        console.log(rateData)

    },[checkRatesApi])
 


    useEffect(()=>{
        getKpgRatesSell()
        // const orderKepengDataFromLocal = localStorage.getItem('sell-transaction')
        const orderKepengDataFromLocal = localStorage.getItem('kepeng-transaction')

        const dataKepengQuantity = JSON.parse(orderKepengDataFromLocal)
        const publicAddressFromLocal = localStorage.getItem('public-address-seller')
        const dataSellerPublicAddress =JSON.parse(publicAddressFromLocal)
        setSellPublicAddress(dataSellerPublicAddress)
        setDataKepengQuantity(dataKepengQuantity)
        console.log('----------',dataKepengQuantity)
    },[])
    const onSubmit = async  () =>{
        // const kpgQuantityFromLocal =  localStorage.getItem('sell-transaction');
        // const sellerInfoFromLocal =  localStorage.getItem('public-address-seller');

        // const kpgForm = JSON.parse(kpgQuantityFromLocal);
        // const sellerInfoForm = JSON.parse(sellerInfoFromLocal);
        const data ={
            quantity:dataKepengQuantity?.amount_kpg,
            public_address:dataKepengQuantity?.public_address,
            rate: dataKepengQuantity?.rate,
            total:dataKepengQuantity?.convertKpgToIDR,
            bank,
            account_number,
            account_name
        }
        if(bank === ''||account_number === '' ||account_name === ''){
            setShow(true)
            setTimeout(()=>{
                setShow(false)
            },3000)
            console.log('please enter your public address')
        }else if (checked === false){
            console.log('check',false)
            toast.error('Please check to confirm the transaction')
        }
        else{
            
            const response = await sellKepeng(data)
            console.log('sell response ----',response)
            if(response.error){
                console.log("sell unsuccess",response.message)
            }else{
                const selldata ={
                    quantity:dataKepengQuantity?.amount_kpg,
                    public_address:dataKepengQuantity?.public_address,
                    status:response?.statusTransfer,
                    rate:dataKepengQuantity?.rate,
                    bank,
                    account_number,
                    account_name,
                    time:response?.time,
                    total:dataKepengQuantity?.convertKpgToIDR,
                    transfer_to:response?.transfer_to,
                    transaction_id:response?.transaction_id

                }
                // console.log(data)
                // localStorage.setItem('sell-transaction',JSON.stringify(selldata))
                localStorage.setItem('kepeng-transaction',JSON.stringify(selldata))
                console.log('quantitiy',dataKepengQuantity?.amount_kpg)
                console.log('id',response?.transaction_id)

                console.log('sell data',data)

                console.log('sell data',selldata)
                handledClick(response?.transaction_id)
            }


            
           
        }
    }

    return (
        <div>
            <NavbarSell/>
            <Container className="bankaccountontainer" fluid="sm">
            <Form>
             <Row className="mainRowbank">
                    <Col className='input_bank_info' xs={8}>
                    <Form.Label className="bankaccountlabel">Bank Account (2/2)</Form.Label>
                    <Form.Text className="fillbankaccount">
                    Enter bank account information to receive Kepeng (KPG) sales to Rupiah (IDR)              
                    </Form.Text>
                    <Form.Group>
                        <Form.Label className="bankinformation">Choose Bank</Form.Label>
                        <Form.Select aria-label="Default select example"
                        onChange={(event)=>{
                            const selectedBank = event.target.value;
                            setBank(selectedBank);
                        }}
                        >
                        <option>Choose Bank</option>
                        <option value="BCA" >BCA</option>
                        <option value="MANDIRI">Mandiri</option>
                        <option value="BNI">BNI</option>
                        <option value="BRI">BRI</option>

                        </Form.Select>
                        {/* <Form.Control 
                        type="text" 
                        placeholder="Choose Bank" 
                        value={bank}
                        onChange={(event)=>setBank(event.target.value)}
                        /> */}
                        <Form.Label className="bankinformation">Account Number</Form.Label>
                        <Form.Control 
                        type="number" 
                        placeholder="Input Account Number" 
                        value={account_number}
                        onChange={(event)=>setaccount_number(event.target.value)}
                        />
                        <Form.Label className="bankinformation">Account Name</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="Input Account Name" 
                        value={account_name}
                        onChange={(event)=>setaccount_name(event.target.value)}
                        />
                    </Form.Group>
                    {/* </Form> */}
                    <Row>
                    {
                    show?<Alert className="nothavewallet" variant="danger">
                    <img  className="alertlogo" src={alertLogo}/>
                    <p className="nothavewallettext">
                     Please full fill all the form
                    </p>

                    </Alert>:null
                    }

                    </Row>
                    
                   
                    </Col>
                    <Col className='nominal_kepeng_info' xs={4}>
                    {/* <Form> */}
                    <Form.Label className="ordersummarybank">Order Summary</Form.Label>
                    <Form.Text className="conversionidr">
                        Sell - {dataKepengQuantity?.quantity} KPG 
                        <span>
                        <NumberFormat 
                        value={dataKepengQuantity?.convertKpgToIDR} 
                        displayType="text" 
                        thousandSeparator=","
                        decimalSeparator="."
                        />
                            &nbsp;IDR  </span>
                    </Form.Text>
                    <Form.Text className="fee">
                       Admin fee <span><NumberFormat 
                            value={dataKepengQuantity?.adminFee}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR </span>
                    </Form.Text>
                    <Form.Text className="totalrecieve">
                       Total Recieve 
                       <span>
                        <NumberFormat 
                        value={dataKepengQuantity?.totalRecieve} 
                        displayType="text" 
                        thousandSeparator=","
                        decimalSeparator="."
                        />
                            &nbsp;IDR  </span>
                    </Form.Text>
                    <Form.Check 
                        className='checkbox-notallow-moneylaundry py-4'
                        type='checkbox'
                        onChange={()=>setChecked(true)}
                        label="By ticking, you are confirming this transaction is not for Money     Laundering"
                    />
                    <Row className="allbuttonrow">
                        <Row className="eachbtn">
                            <Button 
                            className="buttonnext" 
                            variant="primary" 
                            type="button"
                            onClick={onSubmit}>
                                Next
                            </Button>
                        </Row>
                        <Row className="eachbtnrow">
                            <Link to="/seller-info"className="buttonback">
                            {/* <Button className="buttonback" variant="light" type="submit"> */}
                                Back
                            {/* </Button> */}
                            </Link>
                        </Row>
                    </Row>
                   
                  
                    </Col>

              

            </Row>
            </Form>
            <ToastContainer/>
        </Container>
        <Footer/>
        </div>
    );
}

export default BankAccount;