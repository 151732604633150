import React from 'react'

import {Container} from 'react-bootstrap'

const SmartestChoiceTitle = () => {
  return (
   <Container>
    <div id='services' className='smartest-choice-title'>
        <div className="smartest-choice-texts">
            <h2 className='smartest-choice-text-1'>The smartest choice for <span className='smartest-choice-text-2'>Kepeng (KPG) Exchange</span></h2>
            <h4 className='smartest-choice-subtext'>Whether you're looking for Kepeng Exchange, you will find the perfect changer on GrahaKPG</h4>
        </div>
    </div>
     </Container>
  )
}

export default SmartestChoiceTitle