import React from 'react'
import GrahaFooterLogo from '../../assets/graha_logo.png'
import {Container} from 'react-bootstrap'
import './DetailHistoryTransactionFooter.css'

const DetailHistoryTransactionFooter = () => {
  return (
    <div className='FooterDetailTransactionHistory-wrapper'>
        <Container>
        <div className="FooterDetailTransactionHistory-info">
            {/* <hr className='line-FooterTransactionHistory'/> */}
                <img className='graha-logo-FooterDetailTransactionHistory' src={GrahaFooterLogo} alt="/" />       
                <h4 className='copyright-FooterDetailTransactionHistory'>@ Copyright GrahaKPG 2022. All Rights Reserved</h4> 
            </div>
        </Container>
    </div>
  )
}

export default DetailHistoryTransactionFooter