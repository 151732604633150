import React, { useEffect, useState } from 'react';
import './BuyTransactionDetail.css'
import copyIcon from '../../assets/copy-icon.png'
import NumberFormat from 'react-number-format';


import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap';
import NavbarSell from '../../Sell/Navbar/NavSell';
import Footer from '../../Sell/Footer/Footer';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';



function BuyTransactionDetail() {
    const transactionId = "0xc98a8ec7a07f1b743e86896a5243"
//     const VANumber="12412773267984933"
    const [copied,setCopied] = useState(false)
    const [status,setStatus]=useState('')
    const [VANumber,setVANumber]=useState('')
    const [dueTime,setDueTime]=useState('')
    const [statusUnpaid,setStatusUnpaid] = useState(false)
    const [onProgress,setOnProgress] = useState(false)
    const [statusComplete,setStatusComplete] = useState(false)
    const [statusExpired,setStatusExpired] = useState(false)





    const [buyKPG,setBuyKPG] = useState({
            quantity:0,
            convertKpgToIDR:0

    })
    
    const [buyPublicAddress,setBuyPublicAddress] = useState({
        receiverPublicAddress:''
    })

    useEffect(()=>{
        const orderKepengDataFromLocal = localStorage.getItem('buy-kepeng')
        const dataKepengOrderBuy = JSON.parse(orderKepengDataFromLocal)
        // const publicAddressFromLocal = localStorage.getItem('Public-Address-Receiver')
        // const dataReceiverPublicAddress =JSON.parse(publicAddressFromLocal)

        console.log(dataKepengOrderBuy)
        console.log(dataKepengOrderBuy.convertKpgToIDR)

        console.log(dataKepengOrderBuy.public_address)
        console.log('va number:',dataKepengOrderBuy.VANumber)

        console.log('STATUS PAYMENT',dataKepengOrderBuy.time)
        setStatus(dataKepengOrderBuy?.status)
        setVANumber(dataKepengOrderBuy.VANumber)
        setDueTime(dataKepengOrderBuy.time)
        setBuyKPG(dataKepengOrderBuy)
        

    },[])
    useEffect(()=>{
        if(status === "on progress"){
                setOnProgress(true)
        }else if(status === "waiting for transfer"){
                setStatusUnpaid(true)
        }else if(status === "complete"){
                setStatusComplete(true)
        }else if (status === "expired"){
                setStatusExpired(true)
        }else if (status ==="unpaid"){
                setStatusUnpaid(true)
        }

        console.log('status',status)
    })


    return (
        <div>
             <NavbarSell/>   
            <Container className="contain-all-detail-transaction">
             <Row className="headerRow-detailSell">
                    <Col className="headerCol"  xs={6}>
                    <Form.Label className="tokentransferlabel"> Transfer Token </Form.Label>
                    {/* <hr /> */}
                    </Col>
            </Row>
            <Row className="bodyRow-detailBuy" >
            <Col className="bodyCol" xs={6}>
                    <Form.Label className="finishTransferBeforeLabel">Finish Transfer Before</Form.Label>
                    <Form.Label className="transfertime">{dueTime}</Form.Label>

                    <Form.Label className="transfel-nominal-label">Transfer Nominal </Form.Label>
                    <Form.Label className="total-nominal-kpg">
                    <NumberFormat 
                            value= {buyKPG.total}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR 
                            </Form.Label>
                    <a className="open-Payment-Detail-Href" href={buyKPG.url} target="_blank">Open payment detail </a>   

                    <Form.Label className="transfel-nominal-label">Kepeng amount </Form.Label>
                    <Form.Label className="total-nominal-kpg">{buyKPG.quantity} KPG</Form.Label>

                    <Form.Label className="sendtopublicaddresslabel">Virtual Account Number </Form.Label>
                    <Form.Label className="user-public-address-buy">{VANumber}
                    <CopyToClipboard text={VANumber}
                         onCopy={()=>setCopied(true)}   
                            >   
                    <span className="spanlogo-public-address">
                            <img  className="logocopy" src={copyIcon} alt="" />Copy
                    </span>
                    </CopyToClipboard>
                    </Form.Label>
                    <Form.Label className="user-public-address-buy-mobile">{ VANumber?.length > 15 ?VANumber.substring(0,13)+"...":VANumber}                    
                    
                    <CopyToClipboard text={VANumber}
                         onCopy={()=>setCopied(true)}   
                            >   
                    <span className="spanlogo-public-address-mobile">
                            <img  className="logocopy" src={copyIcon} alt="" />
                    <span className="copy">Copy</span> </span>
                    </CopyToClipboard>
                    </Form.Label>
                    <Form.Label className="transfer-status">Transfer Status </Form.Label>
                    {
                    statusComplete?<Alert className="On-progress-alert-complete" variant="warning">
                            {status}
                    </Alert>:null
                   }
                    {
                    statusUnpaid?<Alert className="On-progress-alert" variant="warning">
                            {status}
                    </Alert>:null
                    }
                    {
                    statusExpired?<Alert className="On-progress-alert-expired" variant="warning">
                            {status}
                    </Alert>:null
                    }
                    {
                    onProgress?<Alert className="On-progress-alert-unpaid" variant="warning">
                            {status}
                    </Alert>:null
                    }
                    <hr className="line"  />
                    <Form.Label className="transfer-type">Transfer type </Form.Label>
                    <Alert className="buy-alert" variant="success">Buy</Alert>
                    <Form.Label className="id-transaction-label">Id Transaction </Form.Label>
                    <Form.Label className="id-transaction">{buyKPG?.transaction_id} 
                    <CopyToClipboard text={buyKPG?.transaction_id}
                         onCopy={()=>setCopied(true)}   
                            >   
                    <span className="spanlogo-id-transaction">
                            <img  className="logocopy" src={copyIcon} alt="" /> Copy
                   </span>
                   </CopyToClipboard>
                
                    </Form.Label>
                    <Form.Label className="id-transaction-mobile" id="id-transaction-mobile">{ buyKPG?.transaction_id?.length > 15 ? buyKPG?.transaction_id.substring(0,15)+"...":buyKPG?.transaction_id} 
                    
                    <CopyToClipboard text={buyKPG?.transaction_id}
                         onCopy={()=>setCopied(true)}   
                            >  
                    <span className="spanlogo-id-transaction-mobile">
                            
                            <img  className="logocopy" src={copyIcon} alt="" /> 
                            <span className="copy">Copy</span> 
                    </span>
                    </CopyToClipboard>
                    </Form.Label>

                    <Form.Label className="user-public-address-sell-ipad">{buyKPG?.transaction_id?.length > 20 ? buyKPG?.transaction_id.substring(0,15)+"...":buyKPG?.transaction_id}                    
                    
                    <CopyToClipboard text={buyKPG?.transaction_id}
                         onCopy={()=>setCopied(true)}   
                            >   
                    <span className="spanlogo-public-address-ipad">
                            <img  className="logocopy" src={copyIcon} alt="" />
                    <span className="copy">Copy</span> </span>
                    </CopyToClipboard>
                    </Form.Label>

                    {/* <img  className="logocopy" src={copyIcon} alt="" />
                    <Form.Text className="copy-public-address">Copy</Form.Text> */}
                    
                     
                    <hr className="line"  />
                    <p>Anda dapat melakukan transfer KPG dengan mengikuti langkah berikut: </p>
                        <h4>Cara pembayaran via ATM BRI</h4>
                    <ol>
                    <li>Pada menu utama, pilih Transaksi Lain</li>
                    <li>Pilih Pembayaran</li>
                    <li>Pilih Lainnya</li>
                    <li>Pilih BRIVA</li>
                    <li>Masukkan Nomor BRIVA 124129357058596749</li>
                    <li>Jumlah pembayaran, 
                            nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                            Jika informasi yang dicantumkan benar,pilih kirim</li>
                    <li>Pembayaran telah selesai. Simpan bukti pembayaran anda.</li>
                    </ol>
                    <h4>Cara pembayaran via Internet Bank BRI</h4>
                    <ol>
                    <li>Masuk pada Internet Banking BRI</li>
                    <li>Pilih menu Pembayaran dan pembelian</li>
                    <li>Pilih sub menu BRIVA</li>
                    <li>Masukkan Nomor BRIVA 124129357058596749</li>
                    <li>Jumlah pembayaran, 
                            nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                            Jika informasi yang dicantumkan benar,pilih kirim</li>
                    <li>Masukan password dan mToken, pilih kirim</li>
                    <li>Pembayaran telah selesai. Simpan bukti pembayaran anda. Untuk mencetak bukti transaksi, pilih Cetak</li>
                    </ol>
                    <h4>Cara pembayaran via BRI mobile</h4>
                    <ol>
                    <li>Masuk ke dalam aplikasi BRI Mobile, pilih Mobile Banking BRI</li>
                    <li>Pilih Pembayaran, lalu pilih BRIVA</li>
                    <li>Masukkan Nomor BRIVA 124129357058596749</li>
                    <li>Jumlah pembayaran, 
                            nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                            Jika informasi yang dicantumkan benar,pilih Continue</li>
                    <li>Masukkan Mobile Banking BRI PIN, pilih Ok</li>
                    <li>Pembayaran telah selesai. Simpan notifikasi sebagai bukti pembayaran</li>
                    </ol>




                    </Col>
                    <Link to='/' className="back-to-homepage">
                        <u>
                                    Back to homepage
                        </u>
                    </Link>
            </Row>
            
        </Container>

        <Footer/>            
        </div>
    );
}

export default BuyTransactionDetail;