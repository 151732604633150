import React from "react";
import EffectiveLogo from "../assets/effective.png";
import TrustedLogo from "../assets/trusted.png";
import CustomerCareLogo from "../assets/care.png";
import BestChangerLogo from "../assets/best.png";
import PeerToPeer from "../assets/peertopeer.png";

import { Col, Row, Container } from "react-bootstrap";

const SmartChoiceInfo = () => {
  return (
    <Container className="container">
      <Col>
        <div className="smart-choice-info">
          <Row className="fast_and_effective_wrapper">
            <div className="fast-and-effective-info">
              <img className="effective-Logo-Img" src={EffectiveLogo} alt="/" />
              <h3 className="effective-title">Fast and Effective</h3>
              <h4 className="effective-subtitle-1">
                No need to wait, you can receive or sell Kepeng (KPG) quickly
                and effectively
              </h4>
              {/* <h4 className='effective-subtitle-2'> Kepeng (KPG) quickly and effectively</h4> */}
            </div>
          </Row>
          <Row className="fast_and_effective_wrapper">
            <div className="fast-and-effective-info-2 d-flex mt-1">
              <img className="effective-Logo-Img" src={PeerToPeer} alt="/" />
              <h3 className="effective-title">Peer to Peer</h3>
              <h4 className="effective-subtitle-1">
                Decentralized platform that simplify transactions by connecting
                parties to one another
              </h4>
              {/* <h4 className='effective-subtitle-2'> Kepeng (KPG) quickly and effectively</h4> */}
            </div>
          </Row>

          <Row className="trusted_and_save_info_wrapper">
            <div className="trusted-and-save-info">
              <img className="trusted-Logo-Img" src={TrustedLogo} alt="/" />
              <h3 className="trusted-title">Trusted and Safe</h3>
              <h4 className="trusted-subtitle-1">
                We always try our best, give an convinience and protect all
                data, so you can feel safe to do transaction at GrahaKPG
              </h4>
              {/* <h4 className='trusted-subtitle-2'> </h4>
            <h4 className='trusted-subtitle-3'></h4> */}
            </div>
          </Row>

          <Row className="customer_care_info_wrapper">
            <div className="customer-care-info">
              <img
                className="customer-care-Img"
                src={CustomerCareLogo}
                alt="/"
              />
              <h3 className="customer-care-title">Customer Care</h3>
              <h4 className="customer-care-subtitle-1">
                Have a problem with the transaction? Don't worry, our team ready
                to solve your problem in GrahaKPG
              </h4>
              {/* <h4 className='customer-care-subtitle-2'>worry, our team ready to solve your problem</h4>
            <h4 className='customer-care-subtitle-3'>in GrahaKPG</h4> */}
            </div>
          </Row>

          <Row className="best_changer_info_wrapper">
            <div className="best-changer-info">
              <img className="best-changer-Img" src={BestChangerLogo} alt="/" />
              <h3 className="best-changer-title">Best Changer</h3>
              <h4 className="best-changer-subtitle-1">
                GrahaKPG is the best Kepeng (kpg) changer in the world, so you
                can enjoy our experience to buy and sell kepeng (KPG)
              </h4>
              {/* <h4 className='best-changer-subtitle-2'>the world, so you can enjoy our experience</h4>
            <h4 className='best-changer-subtitle-3'>to buy and sell kepeng (KPG)</h4> */}
            </div>
          </Row>
        </div>
      </Col>
    </Container>
  );
};

export default SmartChoiceInfo;
