import React, { useEffect, useState } from "react";
import Navbar from "../Homepage/Navbar";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "./VerificationStep2.css";
import DropdownArrow from "../assets/dropdownArrow.png";
import { useNavigate } from "react-router-dom";
import FooterVerifyAccount from "../VerifiedAccountChooseCountry/FooterVerifyAccount/FooterVerifyAccount";

import alertLogo from "../assets/alertlogo.png";

const VerificationStep2 = () => {
  const [indentityValue, setIndentityValue] = useState("");
  // ktp data
  const [ktpID, setKtpId] = useState("");
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  //passport data
  const [placeOfIssue, setPlaceOfIssue] = useState("");
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [dateOfExpiry, setDateOfExpiry] = useState("");
  const [surname, setSurname] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [nationality, setNationality] = useState("");
  const [userCountry, setUserCountry] = useState();

  //alertMassage
  const [showAlertIdentityType, setShowAlertIdentityType] = useState(false);
  const [alertEmail, setAlertEmail] = useState(false);
  const [alertEmailFormat, setAlertEmailFormat] = useState(false);
  const [alertFullname, setAlertFullname] = useState(false);
  const [alertNIK, setAlertNIK] = useState(false);
  const [alertAddress, setAlertAddress] = useState(false);
  const [alertGender, setAlertGender] = useState(false);
  const [alertPhoneNumber, setAlertPhoneNumber] = useState(false);
  const [alertPhoneNumberLength, setAlertPhoneNumberLength] = useState(false);
  const [alertPlaceOfIssue, setAlertPlaceOfIssue] = useState(false);
  const [alertDateOfIssue, setAlertDateOfIssue] = useState(false);
  const [alertDateOfExpiry, setAlertDateOfExpiry] = useState(false);
  const [alertSurname, setAlertSurname] = useState(false);
  const [alertPassportNumber, setAlertPassportNumber] = useState(false);
  const [alertDateOfBirth, setAlertDateOfBirth] = useState(false);
  const [alertNationality, setAlertNationality] = useState(false);

  const [gender, setGender] = useState("");
  const [datauserCountry, setDatauserCountry] = useState({
    country: "",
  });

  const moveToNextKYCStep = useNavigate();

  function handleClick() {
    moveToNextKYCStep("/upload_identity_photo");
  }
  useEffect(() => {
    const kycInfoFromLocal = localStorage.getItem("kyc-information");
    const kycInformation = JSON.parse(kycInfoFromLocal);
    setDatauserCountry(kycInformation);
    setUserCountry(kycInformation?.valueCountry);
    console.log("-----------", kycInformation?.valueCountry);
    console.log("----------- user country", userCountry);

    if (kycInformation?.valueCountry !== "Indonesia") {
      // indentityValue = "PASSPORT"

      setIndentityValue("PASSPORT");
      console.log("----------- identity", indentityValue);
    } else {
      // indentityValue = 'KTP'

      setIndentityValue("");
      console.log("----------- identity", indentityValue);
    }

    // console.log('country user --- 1',datauserCountry)
  }, []);

  // console.log('----------- identity 3',indentityValue)

  // useEffect(()=>{
  //     console.log('country user',userCountry)
  //     if(userCountry !== 'Indonesia'){
  //         // indentityValue = "PASSPORT"
  //         console.log('----------- identity',indentityValue)

  //         setIndentityValue('PASSPORT')
  //     }else{
  //         // indentityValue = 'KTP'
  //         console.log('----------- identity',indentityValue)
  //         setIndentityValue('KTP')
  //     }

  // },[])

  // console.log(indentityValue)
  // console.log('country user',userCountry)
  // if(datauserCountry?.valueCountry !== 'Indonesia'){
  //     setIndentityValue('KTP')
  // }else{
  //     setIndentityValue('PASSPORT')
  // }

  const onSubmit = async () => {
    const dataKTP = {
      country: datauserCountry?.valueCountry,
      public_address: datauserCountry?.public_address,
      indentityValue,
      ktpID,
      fullName,
      address,
      phoneNumber,
      email,
      gender,
    };

    const dataPassport = {
      country: datauserCountry?.valueCountry,
      public_address: datauserCountry?.public_address,
      indentityValue,
      placeOfIssue,
      dateOfIssue,
      dateOfExpiry,
      surname,
      passportNumber,
      gender,
      dateOfBirth,
      nationality,
      phoneNumber,
      email,
    };

    if (indentityValue === "") {
      console.log("please input your personal information");
      setShowAlertIdentityType(true);
      setTimeout(() => {
        setShowAlertIdentityType(false);
      }, 3000); // console.log('KTP :',dataKTP)
      // KTP FORM VALIDATION
    } else {
      if (indentityValue === "KTP") {
        if (ktpID === "") {
          console.log("PLEASE ENTER YOUR NIK");
          setAlertNIK(true);
          setTimeout(() => {
            setAlertNIK(false);
          }, 3000);
        } else if (ktpID.length > 16) {
          console.log("more than 16");
          setAlertNIK(true);
          setTimeout(() => {
            setAlertNIK(false);
          }, 3000);
        } else if (ktpID.length < 16) {
          console.log("less than 16");
          setAlertNIK(true);
          setTimeout(() => {
            setAlertNIK(false);
          }, 3000);
        } else if (fullName === "") {
          console.log("name empty");
          setAlertFullname(true);
          setTimeout(() => {
            setAlertFullname(false);
          }, 3000);
        } else if (address === "") {
          console.log("address empty");
          setAlertAddress(true);
          setTimeout(() => {
            setAlertAddress(false);
          }, 3000);
        } else if (gender === "") {
          console.log("gender empty");
          setAlertGender(true);
          setTimeout(() => {
            setAlertGender(false);
          }, 3000);
        } else if (phoneNumber === "") {
          console.log("phone empty");
          setAlertPhoneNumber(true);
          setAlertPhoneNumberLength(false);
          setTimeout(() => {
            setAlertPhoneNumber(false);
          }, 3000);
        } else if (phoneNumber.length < 7) {
          console.log("not enough length");
          setAlertPhoneNumberLength(true);
          setAlertPhoneNumber(false);
          setTimeout(() => {
            setAlertPhoneNumberLength(false);
          }, 3000);
        } else if (email === "") {
          console.log("email empty");
          setAlertEmail(true);
          setTimeout(() => {
            setAlertEmail(false);
          }, 3000);
        } else if (
          email === "" ||
          email.includes("@") === false ||
          email.includes(".com") === false
        ) {
          console.log("please enter valid email format using @");
          console.log("----- not using @", email.includes("@"));
          console.log("----- not using .com", email.includes(".com"));
          setAlertEmail(false);
          setAlertEmailFormat(true);
          setTimeout(() => {
            setAlertEmailFormat(false);
          }, 3000);
        } else {
          console.log("KTP :", dataKTP);
          localStorage.setItem("kyc-information", JSON.stringify(dataKTP));
          handleClick();
        }
      } else {
        if (placeOfIssue === "") {
          console.log("POC empty");
          setAlertPlaceOfIssue(true);
          setTimeout(() => {
            setAlertPlaceOfIssue(false);
          }, 3000);
        } else if (dateOfIssue === "") {
          console.log("DOI empty");
          setAlertDateOfIssue(true);
          setTimeout(() => {
            setAlertDateOfIssue(false);
          }, 3000);
        } else if (dateOfExpiry === "") {
          console.log("DOE empty");
          setAlertDateOfExpiry(true);
          setTimeout(() => {
            setAlertDateOfExpiry(false);
          }, 3000);
        } else if (surname === "") {
          console.log("surname empty");
          setAlertSurname(true);
          setTimeout(() => {
            setAlertSurname(false);
          }, 3000);
        } else if (passportNumber === "") {
          console.log("pasport empty");
          setAlertPassportNumber(true);
          setTimeout(() => {
            setAlertPassportNumber(false);
          }, 3000);
        } else if (gender === "") {
          console.log("gender empty");
          setAlertGender(true);
          setTimeout(() => {
            setAlertGender(false);
          }, 3000);
        } else if (dateOfBirth === "") {
          console.log("DOB empty");
          setAlertDateOfBirth(true);
          setTimeout(() => {
            setAlertDateOfBirth(false);
          }, 3000);
        } else if (nationality === "") {
          console.log("NATIONALITY empty");
          setAlertNationality(true);
          setTimeout(() => {
            setAlertNationality(false);
          }, 3000);
        } else if (phoneNumber === "") {
          console.log("phone empty");
          setAlertPhoneNumber(true);
          setAlertPhoneNumberLength(false);
          setTimeout(() => {
            setAlertPhoneNumber(false);
          }, 3000);
        } else if (phoneNumber.length < 7 || phoneNumber.length > 15) {
          console.log("not enough length");
          setAlertPhoneNumberLength(true);
          setAlertPhoneNumber(false);
          setTimeout(() => {
            setAlertPhoneNumberLength(false);
          }, 3000);
        } else if (email === "") {
          console.log("email empty");
          setAlertEmail(true);
          setTimeout(() => {
            setAlertEmail(false);
          }, 3000);
        } else if (
          email === "" ||
          email.includes("@") === false ||
          email.includes(".com") === false
        ) {
          console.log("please enter valid email format using @");
          console.log("----- not using @", email.includes("@"));
          console.log("----- not using .com", email.includes(".com"));
          setAlertEmail(false);
          setAlertEmailFormat(true);
          setTimeout(() => {
            setAlertEmailFormat(false);
          }, 3000);
        } else {
          console.log("Passport", dataPassport);
          localStorage.setItem("kyc-information", JSON.stringify(dataPassport));
          handleClick();
        }
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="verification_step_2_wrapper d-flex flex-column justify-content-center mx-auto">
        <Container className="container_verification_step_2_form d-flex flex-column justify-content-center align-items-center px-5">
          <Row className="d-flex flex-column w-100 pb-5">
            <h2
              className="title_step_1 d-flex justify-content-center w-auto"
              style={{
                color: "white",
                fontSize: "38px",
                fontWeight: "500",
                fontFamily: "inter",
              }}
            >
              Verified your account
            </h2>
            <h2
              className="subtitile_step_1 d-flex justify-content-center w-auto texts-align-center"
              style={{
                fontSize: "20px",
                fontFamily: "inter",
                fontWeight: "300",
                color: "white",
              }}
            >
              Please complete all of the forms below to verify your account
            </h2>
          </Row>
          <div className="verification_step_2__form d-flex flex-column w-75">
            <Row className="flex-column">
              <Col className="px-5 pb-3">
                <h2
                  className="pt-3 d-flex"
                  style={{
                    fontSize: "32px",
                    fontFamily: "inter",
                    fontWeight: "500",
                    color: "#2C3131",
                  }}
                >
                  Identity (2/3)
                </h2>

                <h2
                  className="mt-1 d-flex"
                  style={{
                    fontSize: "20px",
                    fontWeight: "300",
                    fontFamily: "inter",
                    color: "#A9A9A9",
                  }}
                >
                  Please fill your identity
                </h2>

                <hr />

                <h2
                  className="mt-1 d-flex"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "inter",
                    color: "#2C3131",
                  }}
                >
                  Choose identity
                </h2>

                <Form.Select
                  className="dropdown_identity_type"
                  aria-label="Default select example"
                  onChange={(event) => {
                    const selectedIdentity = event.target.value;
                    setIndentityValue(selectedIdentity);
                  }}
                >
                  {userCountry === "Indonesia" && (
                    <option value="">Choose your identity</option>
                  )}
                  {userCountry === "Indonesia" && (
                    <option value="KTP">KTP</option>
                  )}
                  {userCountry === "Indonesia" && (
                    <option value="PASSPORT">Passport</option>
                  )}
                  {userCountry !== "Indonesia" && (
                    <option value="PASSPORT">Passport</option>
                  )}
                </Form.Select>
                {showAlertIdentityType ? (
                  <Alert className="nothavewallet" variant="danger">
                    <img className="alertlogo" src={alertLogo} />
                    <p className="nothavewallettext">
                      Please choose your identity KTP or Passport
                    </p>
                  </Alert>
                ) : null}

                {indentityValue === "KTP" && (
                  <Col>
                    <h2
                      className="mt-4 d-flex"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "inter",
                        color: "#2C3131",
                      }}
                    >
                      KTP ID (NIK)
                    </h2>

                    <input
                      className="w-100 ps-3 mb-3 input_verification_2_form"
                      style={{ height: "62px" }}
                      type="text"
                      placeholder="Enter NIK"
                      value={ktpID}
                      onChange={(event) => setKtpId(event.target.value)}
                    />
                    {alertNIK ? (
                      <h3 style={{ fontSize: "16px", color: "red" }}>
                        Please input your KTP ID (NIK) Make sure your NIK length
                        equal to 16 digit{" "}
                      </h3>
                    ) : null}

                    <h2
                      className="mt-3 d-flex"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "inter",
                        color: "#2C3131",
                      }}
                    >
                      full name
                    </h2>

                    <input
                      className=" w-100 ps-3 mb-3 input_verification_2_form"
                      style={{ height: "62px" }}
                      type="text"
                      placeholder="Enter full name"
                      value={fullName}
                      onChange={(event) => setFullName(event.target.value)}
                    />
                    {alertFullname ? (
                      <h3 style={{ fontSize: "16px", color: "red" }}>
                        Please input your full name
                      </h3>
                    ) : null}

                    <h2
                      className="mt-3 d-flex"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "inter",
                        color: "#2C3131",
                      }}
                    >
                      Address
                    </h2>

                    <input
                      className=" w-100 ps-3 mb-3 input_verification_2_form"
                      style={{ height: "62px" }}
                      type="text"
                      placeholder="Enter address"
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                    />
                    {alertAddress ? (
                      <h3 style={{ fontSize: "16px", color: "red" }}>
                        Please input your address
                      </h3>
                    ) : null}

                    <h2
                      className="mt-3 d-flex"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "inter",
                        color: "#2C3131",
                      }}
                    >
                      Gender
                    </h2>

                    <Form.Select
                      className="dropdown_gender_type"
                      aria-label="Default select example"
                      onChange={(event) => {
                        const selectedGender = event.target.value;
                        setGender(selectedGender);
                      }}
                    >
                      {/* <option>All</option> */}
                      <option value="">Choose your gender</option>
                      <option value="Man">Man</option>
                      <option value="Women">Women</option>
                    </Form.Select>
                    {alertGender ? (
                      <h3 style={{ fontSize: "16px", color: "red" }}>
                        Please choose your gender
                      </h3>
                    ) : null}

                    <h2
                      className="mt-3 d-flex"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "inter",
                        color: "#2C3131",
                      }}
                    >
                      Phone number
                    </h2>

                    <input
                      className=" w-100 ps-3 mb-3 input_verification_2_form"
                      style={{ height: "62px" }}
                      type="text"
                      placeholder="Enter your phone number"
                      value={phoneNumber}
                      onChange={(event) => setPhoneNumber(event.target.value)}
                    />
                    {alertPhoneNumber ? (
                      <h3 style={{ fontSize: "16px", color: "red" }}>
                        Please input your phone number
                      </h3>
                    ) : null}
                    {alertPhoneNumberLength ? (
                      <h3 style={{ fontSize: "16px", color: "red" }}>
                        Phone number length must be more than or equal to 7 and
                        less than 15
                      </h3>
                    ) : null}

                    <h2
                      className="mt-3 d-flex"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "inter",
                        color: "#2C3131",
                      }}
                    >
                      Email
                    </h2>

                    <input
                      className=" w-100 ps-3 mb-3 input_verification_2_form"
                      style={{ height: "62px" }}
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    {alertEmail ? (
                      <h3 style={{ fontSize: "16px", color: "red" }}>
                        Please input your email
                      </h3>
                    ) : null}
                    {alertEmailFormat ? (
                      <h3 style={{ fontSize: "16px", color: "red" }}>
                        Email format must be correct (e.g: example@gmail.com)
                      </h3>
                    ) : null}
                  </Col>
                )}
              </Col>
              {indentityValue === "PASSPORT" && (
                <Col className="px-5 pb-3">
                  <h2
                    className="mt-3 d-flex"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "inter",
                      color: "#2C3131",
                    }}
                  >
                    Place of issue
                  </h2>

                  <input
                    className=" w-100 ps-3 mb-3 input_verification_2_form"
                    style={{ height: "62px" }}
                    type="text"
                    placeholder="Enter place of issue"
                    value={placeOfIssue}
                    onChange={(event) => setPlaceOfIssue(event.target.value)}
                  />
                  {alertPlaceOfIssue ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Please input passport place of issue
                    </h3>
                  ) : null}

                  <h2
                    className="mt-3 d-flex"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "inter",
                      color: "#2C3131",
                    }}
                  >
                    Date of issue
                  </h2>

                  <input
                    className=" w-100 ps-3 mb-3 pe-3 input_verification_2_form"
                    style={{ height: "62px" }}
                    type="date"
                    placeholder="Enter date of issue"
                    value={dateOfIssue}
                    onChange={(event) => setDateOfIssue(event.target.value)}
                  />
                  {alertDateOfIssue ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Please input passport date of issue
                    </h3>
                  ) : null}

                  <h2
                    className="mt-3 d-flex"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "inter",
                      color: "#2C3131",
                    }}
                  >
                    Date of expiry
                  </h2>

                  <input
                    className=" w-100 ps-3 mb-3 pe-3 input_verification_2_form"
                    style={{ height: "62px" }}
                    type="date"
                    placeholder="Enter date of expiry"
                    value={dateOfExpiry}
                    onChange={(event) => setDateOfExpiry(event.target.value)}
                  />
                  {alertDateOfExpiry ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Please input passport date of expiry
                    </h3>
                  ) : null}

                  <h2
                    className="mt-3 d-flex"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "inter",
                      color: "#2C3131",
                    }}
                  >
                    Surname
                  </h2>

                  <input
                    className=" w-100 ps-3 mb-3 input_verification_2_form"
                    style={{ height: "62px" }}
                    type="text"
                    placeholder="Enter surname"
                    value={surname}
                    onChange={(event) => setSurname(event.target.value)}
                  />
                  {alertSurname ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Please input your surname
                    </h3>
                  ) : null}

                  <h2
                    className="mt-3 d-flex"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "inter",
                      color: "#2C3131",
                    }}
                  >
                    Passport number
                  </h2>

                  <input
                    className=" w-100 ps-3 mb-3 input_verification_2_form"
                    style={{ height: "62px" }}
                    type="text"
                    value={passportNumber}
                    onChange={(event) => setPassportNumber(event.target.value)}
                    placeholder="Enter passport number"
                  />
                  {alertPassportNumber ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Please input your passport number
                    </h3>
                  ) : null}

                  <h2
                    className="mt-3 d-flex"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "inter",
                      color: "#2C3131",
                    }}
                  >
                    Gender
                  </h2>

                  <Form.Select
                    className="dropdown_gender_type"
                    aria-label="Default select example"
                    onChange={(event) => {
                      const selectedGender = event.target.value;
                      setGender(selectedGender);
                    }}
                  >
                    <option value="">Choose your gender</option>
                    <option value="Man">Man</option>
                    <option value="Women">Women</option>
                  </Form.Select>
                  {alertGender ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Please choose your gender
                    </h3>
                  ) : null}

                  <h2
                    className="mt-3 d-flex"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "inter",
                      color: "#2C3131",
                    }}
                  >
                    Date of Birth
                  </h2>

                  <input
                    className=" w-100 ps-3 mb-3 pe-3 input_verification_2_form"
                    style={{ height: "62px" }}
                    type="date"
                    placeholder="Enter date of birth"
                    value={dateOfBirth}
                    onChange={(event) => setDateOfBirth(event.target.value)}
                  />
                  {alertDateOfBirth ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Please input your date of birth
                    </h3>
                  ) : null}

                  <h2
                    className="mt-3 d-flex"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "inter",
                      color: "#2C3131",
                    }}
                  >
                    Nationality
                  </h2>

                  <input
                    className=" w-100 ps-3 mb-3 input_verification_2_form"
                    style={{ height: "62px" }}
                    type="text"
                    placeholder="Enter your phone nationality"
                    value={nationality}
                    onChange={(event) => setNationality(event.target.value)}
                  />

                  {alertNationality ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Please input your nationality
                    </h3>
                  ) : null}

                  <h2
                    className="mt-3 d-flex"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "inter",
                      color: "#2C3131",
                    }}
                  >
                    Phone number
                  </h2>

                  <input
                    className=" w-100 ps-3 mb-3 input_verification_2_form"
                    style={{ height: "62px" }}
                    type="text"
                    placeholder="Enter your phone number"
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                  {alertPhoneNumber ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Please input your phone number
                    </h3>
                  ) : null}
                  {alertPhoneNumberLength ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Phone number length must be more than or equal to 7 and
                      less than 15
                    </h3>
                  ) : null}

                  <h2
                    className="mt-3 d-flex"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "inter",
                      color: "#2C3131",
                    }}
                  >
                    Email
                  </h2>

                  <input
                    className=" w-100 ps-3 mb-3 input_verification_2_form"
                    style={{ height: "62px" }}
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  {alertEmail ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Please input your email
                    </h3>
                  ) : null}
                  {alertEmailFormat ? (
                    <h3 style={{ fontSize: "16px", color: "red" }}>
                      Email format must be correct (e.g: example@gmail.com)
                    </h3>
                  ) : null}
                </Col>
              )}

              <Col className="button_wrapper d-flex flex-row align-items-text justify-content-between px-5 pb-5">
                <a
                  href="/verification_1_grahakpg"
                  className="a_button_back w-100 pe-3 "
                >
                  <button className="w-100 mt-4 button_back">Back</button>
                </a>

                <a onClick={onSubmit} className="a_button_next w-100 ps-3">
                  <button className="w-100 mt-4 button_next">Next</button>
                </a>
              </Col>
            </Row>
          </div>
        </Container>
        <FooterVerifyAccount />
      </div>
    </div>
  );
};

export default VerificationStep2;
