import React from 'react'
import Navbar from '../Homepage/Navbar'
import Form from 'react-bootstrap/Form';
import { Container,Row,Col } from 'react-bootstrap'
import './Signin.css'

const Signin = () => {
  return (
    <div>
       <Navbar/>
       <div className='signin_wrapper d-flex flex-row justify-content-center mx-auto'>
        <Container className='container_signin_form d-flex justify-content-center px-5'>
            <div className="signin__form d-flex flex-column">
                <Row>
                    <Col className='px-5 pb-5'>
                        <h2 className=' mt-5' style={{fontSize:'32px',fontFamily:'inter', fontWeight:'500',color:'#34495e' }}>Sign in</h2>
                        
                        <h2 className=' ' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'300'}}>Already have an account?&nbsp;<a className='a_register' href='/register_grahakpg'><span style={{fontWeight:'700', color:'#34495E'}}>Register</span></a> </h2>
                        
                        <h2 className=' mt-4' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Email</h2>

                        <input className=' w-100 ps-3 input_signin' style={{height:'62px'}} type="text" placeholder='Your Email' />

                        
                        <h2 className=' mt-4' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Password</h2>

                        <input className=' w-100 ps-3 input_signin' style={{height:'62px'}} type="text" placeholder='Password' />

                        <Form className='mt-4 d-flex flex-row w-100'>
                            {['checkbox'].map((type) => (
                                <div key={`default-${type}`} className="d-flex flex-row align-items-center w-100">
                                <Form.Check 
                                    type={type}
                                    id={`default-${type}`}
                                />
                                 <h2 className='px-3 my-auto' style={{fontSize:'16px', fontWeight:'300', fontFamily:'inter'}}>Remember me</h2>
                                </div>
                            ))}
                            
                            <a href='/forgot_password_grahakpg' className='a_forgot_pass d-flex flex-row w-50'><h2 className='forgot_password d-flex my-auto'>Forgot Password?</h2></a>
                            </Form>

                        <button className='w-100 mt-4 button_signin' >Sign Up</button>

                    </Col>
                </Row>

            </div>
        </Container>
        </div>
    </div>
  )
}

export default Signin