import React from 'react'
import NavbarHomepage from '../Homepage/Navbar'
import FooterPrivacyPolicy from './PrivacyPolicyFooter/PrivacyPolicyFooter'
import PrivacyPolicyContent from './PrivactyPolicyContents/PrivactyPolicyContents';

const PrivacyPolicyPage = () => {
  return (
    <div className='transaction-history-wrapper'>
        <NavbarHomepage/>
        <PrivacyPolicyContent/>
        <FooterPrivacyPolicy/>
        {/* <Footer/> */}
    </div>
  )
}

export default PrivacyPolicyPage