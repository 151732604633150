import React from 'react'

const Header = () => {
  return (
    <div className="header-wrapper">
        <div className="main-info">
            <h2 className='info-1'>The place where you can buy and sell <span className='bold_header'>Kepeng (KPG)</span><span> easily!</span></h2>
            
        </div>
    </div>
  )
}

export default Header