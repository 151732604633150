import React, { useCallback, useEffect, useState } from 'react';
import './InputNominalBuy.css'
import { Container, Form, Row, Col, Button, Alert, NavLink } from 'react-bootstrap';
// import {ToastContainer , toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import NumberFormat from 'react-number-format';
import AlertLogo from '../../assets/alertlogo.png'
import { useNavigate, Link, Router } from 'react-router-dom';
import NavbarSell from '../../Sell/Navbar/NavSell';
import Footer from '../../Sell/Footer/Footer'
import { checkRatesApi } from '../../../services/Api';


function InputNominal() {

    const [quantity, setInputNominal]= useState('')
    const [showAlert, setShowAlert] = useState(false);
    const [alertWholeNumber,setAlertWholeNumber] = useState(false)
    const [rateKpgBuy,setRateKpgBuy]= useState();
    const [buyData,setBuyData] = useState([])




    const moveToInputPublicAddressPageBuy = useNavigate()

    const convertKpgToIDR = quantity*rateKpgBuy
    const adminFee = convertKpgToIDR*2.5/100;
    const platformFee = 5000;
    const totalBill = convertKpgToIDR + adminFee + platformFee;
    totalBill.toFixed(2)

    const getKpgRatesBuy = useCallback(async() => {
        const rateData = await checkRatesApi();
        setRateKpgBuy(rateData.buy)
        console.log(rateData)

    },[checkRatesApi])

    useEffect(()=>{
        getKpgRatesBuy()

        // const dataBuyFromLocal = localStorage.getItem('buy-kepeng')
        const dataBuyFromLocal = localStorage.getItem('kepeng-transaction')
        const dataBuy = JSON.parse(dataBuyFromLocal)
        setBuyData(dataBuy)
        // setPlatformFee(5000)
        // const subtotal = quantity * rateKpgBuy
        // setConvertKpgToIDR(subtotal)
        // const feeAdmin = subtotal*2.5/100
        // setAdminFee(feeAdmin)
        // const totalPrice = subtotal + feeAdmin + platformFee
        // setTotalBill(totalPrice)
        // console.log("-----subtotal-----",convertKpgToIDR)
        // console.log("------fee admin",adminFee)
        // console.log("platform fee ---",platformFee)
        // console.log("total bill", totalBill)


        if(quantity === 0){
            platformFee = 0
        }
    },[])

    function handleClick(){
        moveToInputPublicAddressPageBuy('/receiver-info')
    }

    const onSubmit = async () => {
        console.log('quantity string',quantity.toString())
        if(quantity ===''){
            setShowAlert(true)
            setTimeout(()=>{
                setShowAlert(false)
            }, 3000)
            console.log('Please input Kepeng nominal first')

        }else if(quantity.toString().includes(',') || quantity.toString().includes('.') ){
            setAlertWholeNumber(true)
            setTimeout(()=>{
                setAlertWholeNumber(false)
            }, 3000)
            console.log('Please input kepeng amount in whole number')

        }else{
            const data = {
                transaction_type:buyData?.transaction_type,
                quantity,
                convertKpgToIDR,
                rate:rateKpgBuy,
                adminFee,
                platformFee,
                totalBill
            }
            // localStorage.setItem('buy-kepeng', JSON.stringify(data))
            localStorage.setItem('kepeng-transaction', JSON.stringify(data))

            
            console.log(data)
            handleClick()
        }

        // const data = {
        //     quantity,
        // };

        // localStorage.setItem('user-form',JSON.stringify(data))
        
        // console.log("data: ",data)
        // if(quantity === ""){
        //     toast.error('Please fill in the Nominal form!')
        // }else{
        //     toast.success('well done. proud of you')
        // }
    }

    return (
        <div>
        <NavbarSell/>

        <Container className="containallitemBuy">
             <Row className="mainRowBuyInputNominal px-4">
                    <Col className="col_inputNominal" xs={8}>
                    <Form>
                    <Form.Label className="buykepeng">Buy Kepeng (KPG) (1/3)</Form.Label>
                    <Form.Text className="fillkepengBuy">
                        Fill the Kepeng nominal that you want to Buy
                    </Form.Text>
                    <Form.Group>
                        <Form.Label className="nominalBuy">Nominal</Form.Label>
                        <Form.Control type="number" value={quantity} name='quantity' placeholder="Input Kepeng Nominal" onChange={(event)=>setInputNominal(event.target.value)}/>
                        
                        <Row>
                            {
                                showAlert?<Alert className="nothavewalletBuy" variant="danger">
                                <img className='alertLogoBuy'
                                    src={AlertLogo}
                                    alt="/"
                                />
                                <p className="nothavewallettextBuy">
                                Please input Kepeng Nominal 
                                </p>
            
                                </Alert>:null
                            }
                        </Row>
                        <Row>
                            {
                                alertWholeNumber?<Alert className="nothavewalletBuy" variant="danger">
                                <img className='alertLogoBuy'
                                    src={AlertLogo}
                                    alt="/"
                                />
                                <p className="nothavewallettextBuy">
                                Please input kepeng amount in whole number
                                </p>
            
                                </Alert>:null
                            }
                        </Row>
                        <Form.Text className="totalidrBuy d-flex flex-row">
                        Buy Rate <strong>&nbsp;Kepeng</strong><strong> &nbsp; (1,00 KPG &nbsp;  = &nbsp;  <NumberFormat 
                        value={rateKpgBuy} 
                        displayType="text" 
                        thousandSeparator=","
                        decimalSeparator="."
                        />  )</strong> 
                        </Form.Text>
                        

                        <Form.Text className="totalidrBuy">
                        Total (Rupiah - IDR)
                        </Form.Text>
                        <Form.Label className="kepengtoidrBuy">
                            <NumberFormat 
                            value={convertKpgToIDR}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                            &nbsp;IDR 
                        </Form.Label>
                    </Form.Group>
                    </Form>
                    
                    </Col>
                    <Col className="col_ordersummary" xs={5}>
                    <Form>
                    <Form.Label className="ordersummaryBuy">Order Summary</Form.Label>
                    <Form.Text className="conversionidrBuy">
                        Buy - {quantity} KPG <span>
                            <NumberFormat 
                            value={convertKpgToIDR}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR  </span>
                    </Form.Text>
                    <Form.Text className="fee">
                       Admin fee <span><NumberFormat 
                            value={adminFee}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR </span>
                    </Form.Text>
                    <Form.Text className="fee">
                       Platform fee <span><NumberFormat 
                            value={platformFee}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR </span>
                    </Form.Text>
                    <Form.Text className="totalrecieveBuy">
                       Total Bill <span><NumberFormat 
                            value={totalBill}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'
                            />
                             &nbsp;IDR </span>
                    </Form.Text>
                    <Row className="allbuttonrowBuy">
                        <Row className="eachbtnBuy">
                            <Button className="buttonnextBuy" onClick={onSubmit} variant="primary" type="button">
                                Next
                            </Button>
                        </Row>
                        <Row className="eachbtnrowBuy">
                            <Link to='/' className="buttonbackBuy"  variant="light" type="button">
                                Back
                            </Link>
                        </Row>
                    </Row>
                    </Form>
                    </Col>
            </Row>
           
        </Container>
        <Footer/>
    
        </div>
    );
}

export default InputNominal;