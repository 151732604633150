import React from 'react'
import Navbar from '../Homepage/Navbar'
import Form from 'react-bootstrap/Form';
import { Container,Row,Col } from 'react-bootstrap'
import './ConfirmationRegister.css'
import TimerLogo from '../assets/timer.png'

const ConfirmationRegister = () => {
  return (
    <div>
    <Navbar/>
    <div className='confirmation_register_wrapper d-flex flex-row justify-content-center mx-auto'>
     <Container className='container_confirmation_register_form d-flex justify-content-center px-5'>
         <div className="confirmation_register__form d-flex flex-column">
             <Row>
                 <Col className='px-5 pb-5'>
                     <img className='d-flex mt-4 mb-3' src={TimerLogo} alt="/" style={{width:'55px', height:'55px'}} />

                     <h2 className='mt-4 d-flex' style={{fontSize:'32px', fontWeight:'600', fontFamily:'inter', color:'#34495e'}}>Verification</h2>
                     
                     <h2 className='d-flex w-100 mt-3' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'300', color:'#A9A9A9'}}>We’ve sent “magic link” to your email. Please check your email and click the link that we’ve sent.</h2>

                     <Row className='d-flex flex-row'>
                         <Col className='d-flex flex-row align-items-text'>

                         <h2 style={{fontSize:'16px',color:'#A9A9A9'}} className='not_a_member d-flex my-auto w-100 justify-content-start mt-3'>Didn't get a link? &nbsp;<a href='/register_grahakpg' className='a_not_member d-flex'><span style={{fontSize:'16px', fontWeight:'600', fontFamily:'inter', color:'#34495e'}}> Resend Link (time)</span></a> </h2>

                         </Col>
                     </Row>

                 </Col>
             </Row>

         </div>
     </Container>
     </div>
 </div>
  )
}

export default ConfirmationRegister