import React, { useEffect, useState } from 'react'
import Navbar from '../Homepage/Navbar'
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Alert } from 'react-bootstrap'
import './ChooseCountry.css'
import CountrySelect from 'react-bootstrap-country-select';
import { useNavigate } from 'react-router-dom';
import FooterVerifyAccount from './FooterVerifyAccount/FooterVerifyAccount'
import WarningLogo from '../assets/warninglogo.png'


const ChooseCountry = () => {

    const [valueCountry, setValueCountry] = useState(null);
    const [publicAddress, setPublicAddress] = useState([])
    const [transactionType, setTransactionType] = useState('')
    const [alertCountry, setAlertCountry] = useState(false)



    const moveToNextKYCStep = useNavigate()
    //   console.log(valueCountry)


    function handleClick() {
        moveToNextKYCStep('/input_identity_form')
    }
    useEffect(() => {
        const getPublicAddressFromLocal = localStorage.getItem('kepeng-transaction')
        const getPublicAddress = JSON.parse(getPublicAddressFromLocal)
        // const getPublicAddressFromLocalBuy = localStorage.getItem('buy_kepeng')
        // const getPublicAddressBuy = JSON.parse(getPublicAddressFromLocalBuy)
        setPublicAddress(getPublicAddress)
        setTransactionType(getPublicAddress?.publicAddress)
        // console.log(transactionType)


    }, [])

    const onSubmit = async () => {
        if (valueCountry === null) {
            console.log('please input country')
            console.log(valueCountry)
            setAlertCountry(true)


        } else {
            const dataCountry = {
                valueCountry: valueCountry?.name,
                public_address: publicAddress?.public_address
                //   transaction_type:publicAddress?.transaction_type
            }
            if (valueCountry === "") {
                console.log('Please choose your country')
                console.log('----', alertCountry)
                setAlertCountry(true)

            } else if (publicAddress?.public_address === 'undefined') {
                console.log('Please make sure to enter your public address')
            }
            else {
                localStorage.setItem('kyc-information', JSON.stringify(dataCountry))
                handleClick()
                console.log(dataCountry)
            }



        }
    }

    return (
        <div>
            <Navbar />
            <div className='verification_step_1_wrapper d-flex flex-column justify-content-center mx-auto'>
                <Container className='container_verification_step_1_form d-flex flex-column justify-content-center align-items-center px-5'>
                    <Row className='d-flex flex-column w-100 pb-5'>
                        <h2 className='title_step_1 d-flex justify-content-center w-auto' style={{ color: 'white', fontSize: '38px', fontWeight: '500', fontFamily: 'inter' }}>Verified your account</h2>
                        <h2 className='subtitile_step_1 d-flex justify-content-center w-auto texts-align-center' style={{ fontSize: '20px', fontFamily: 'inter', fontWeight: '300', color: 'white' }}>Please complete all of the forms below to verify your account</h2>
                    </Row>
                    <div className="verification_step_1__form d-flex flex-column w-75">
                        <Row>
                            <Col className='px-5 pb-5'>
                                <h2 className='pt-3 d-flex' style={{ fontSize: '32px', fontFamily: 'inter', fontWeight: '500', color: '#2C3131' }}>Choose Country (1/3)</h2>

                                <h2 className='mt-1 d-flex' style={{ fontSize: '20px', fontWeight: '300', fontFamily: 'inter', color: '#A9A9A9' }}>Please choose your country</h2>

                                <hr />

                                <h2 className='mt-1 d-flex' style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'inter', color: '#2C3131' }}>Choose your country</h2>

                                <CountrySelect
                                    value={valueCountry}
                                    onChange={setValueCountry}
                                />
                                <Row>
                                    {
                                        alertCountry ? <Alert className="notactiveaccount" variant="danger">
                                            <img className="cautionlogo"
                                                src={WarningLogo} alt='/' />
                                            <p className="notactiveaccounttext">
                                                Please choose your country
                                                <span className='mx-auto' onClick={() => setAlertCountry(false)}
                                                    style={{ cursor: 'pointer' }}>(x)close</span>
                                                {/* You have to activate your account to purchase Kepeng. <a><u><strong>Activated now</strong></u></a>   */}
                                            </p>


                                        </Alert> : null
                                    }
                                </Row>


                                <Row className='d-flex flex-row'>
                                    <Col className='button_wrapper d-flex flex-row align-items-text justify-content-between'>

                                        <a href={`/${publicAddress?.transaction_type === 'sell' ? 'kpg-sell' : 'kpg-buy'}`} className='w-100 px-3'><button className='w-100 mt-4 button_back' >Back</button></a>

                                        <a onClick={onSubmit} className='w-100 px-3'><button className='w-100 mt-4 button_next' >Next</button></a>

                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                    </div>

                </Container>
                <FooterVerifyAccount />
            </div>
        </div>
    )
}

export default ChooseCountry