import React from 'react';
import { Container } from 'react-bootstrap';
import FootergrahaLogo from '../../assets/footerLogo.png'
import './Footer.css'


function Footer() {
    return (

        <div className="footer-wrapper-sell">
        <hr className="footerline-sell"/>
            <Container className="footercontainer">
                <div className="footer-info-sell">
                    <img className="footerimg" src={FootergrahaLogo} alt="" />
                    <span className="footertext">GrahaKPG © 2022
                   

                    </span>
                </div>
            </Container>

        </div>
        // <footer className="">
        // GrahaKPG © 2022         
        // </footer>

    );
}

export default Footer;