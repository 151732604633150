import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import GrahaKPGLogo from '../../assets/graha_logo.png'
import './FooterVerifyAccount.css'

const FooterVerifyAccount = () => {
  return (
    <div className='footer_verify_account_wrapper'>
    
            <Row className='footer_info_wrapper d-flex flex-row pb-5 px-5 w-100'>
                <hr  style={{color:'#4C6379', height:'2px', paddingBottom:'5px'}}/>
                <Col className='footer_info d-flex flex-row justify-content-between'>
                <img className='grahaLogo_footer my-auto pe-3' src={GrahaKPGLogo} alt="/" style={{width:'230px', height:'61px'}}/>
                <h2 className='copyRight_footer my-auto ps-0' style={{fontSize:'16px', color:'white'}}>© Copyright GrahaKPG 2022. All Rights Reserved</h2>
                </Col>
            </Row>

    </div>
  )
}

export default FooterVerifyAccount