import React, { useEffect, useInsertionEffect, useRef, useState } from 'react'
import BaliolaLogo from '../assets/Baliola Base.png'
import KepengLogo from '../assets/Kepeng Base.png'
import BetamaxLogo from '../assets/Betamax Base.png'
import BaliolaHover from '../assets/Baliola.png'
import KepengHover from '../assets/KepengHover.png'
import BetamaxHover from '../assets/Betamax.png'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {Container, Row} from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import 'owl.carousel/dist/assets/owl.theme.default.min.css'


const CompaniesLogo = () => {

  const [isHoveringBaliola, setIsHoveringBaliola] = useState(false)
  const [isHoveringKepeng, setIsHoveringKepeng] = useState(false)
  const [isHoveringBetamax, setIsHoveringBetamax] = useState(false)
  const btnRef = useRef();

  return (
    <Container>
    <div className='companies-logo'>
      <Row>
        <div className="conpanies_logo_title">
          <h2 className='h2_companies_logo'>In collaboration with</h2>
        </div>
      </Row>
    {/* <OwlCarousel className='logo-carousel' items="1" nav dots loop> */}
      <div className="companies-logo-wrapper">
      
            <div className="baliola-logo" >
            <a href='https://baliola.com/' target='_blank'>
                <img className='baliola-img' ref={btnRef} src={isHoveringBaliola? BaliolaHover:BaliolaLogo} onMouseEnter={()=> setIsHoveringBaliola(true)} onMouseLeave={()=>setIsHoveringBaliola(false)} alt="/" />
            </a>
            </div>
            <div className="kepeng-logo">
              <a href='https://kepeng.io/#' target='_blank'>
                <img className='kepeng-img' ref={btnRef} src={isHoveringKepeng? KepengHover:KepengLogo} onMouseEnter={()=> setIsHoveringKepeng(true)} onMouseLeave={()=>setIsHoveringKepeng(false)} alt="/" />
              </a>
            </div>
            <div className="betamax-logo">
              <a href='https://betamax.co/' target='_blank'>
                <img className='betamax-img' ref={btnRef} src={isHoveringBetamax? BetamaxHover:BetamaxLogo} onMouseEnter={()=> setIsHoveringBetamax(true)} onMouseLeave={()=>setIsHoveringBetamax(false)} alt="/" />
              </a>
            </div>

      </div>
      {/* </OwlCarousel> */}
   
    </div>
    
    </Container>
 
  
  )
}

export default CompaniesLogo